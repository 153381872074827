import React, { useState,useEffect } from "react";
import { IselectedLookup } from "../interfaces/multiselect";
import { LookupIds } from "../Shared/Utility";
import CloseIcon from "@mui/icons-material/Close";
import { Field } from "../../store/DataSourceMetaData";


export interface IuserClientRole  {
  "Id": number,
  "IsActive": boolean,
  "UserName": string
  "UserId": number,
  "RoleId": number,
  "ClientId": number,
  "ClientGroupId": string,
  "ClientGroups" : string,
  "checked" : number
}
export interface INewField {
  [key:string] : Field[]
}
interface Iprop {
  coa_instruments : IselectedLookup[]
  updateCoaInstrument? : (id : number) => void
  objName?: string
  filterUpdateCoaInstrument?: Function
}


export const CoaInstrumentSelectedItemView = ({ coa_instruments,updateCoaInstrument, objName, filterUpdateCoaInstrument }: Iprop) => {
  const [value, setValue] = useState<any>({});
  const [hoveredIndex, setHoveredIndex] = useState<string>("");
  useEffect(() => {
    let newValue: any = {};
    coa_instruments.forEach((el) => {
      const val = el.Value.split("||");
      if (val[2]) {
        val[2]
          .split(",")
          .forEach((elem) =>
            newValue[elem]
              ? newValue[elem].push({
                  Id: el.Id,
                  Value: val[0] + "||" + val[1],
                })
              : (newValue[elem] = [
                  { Id: el.Id, Value: val[0] + "||" + val[1] },
                ])
          );
      }
    });
    setValue(newValue);
  }, [coa_instruments]);

  // const updateCoaInstrument = (id : number) => {
  //   console.log("coa_id" ,id);
  // }

  return (
    <>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "25px",
          color: "rgb(0, 170, 231)",
        }}
      >
        {Object.keys(value)?.map((el, i) => (
          <React.Fragment key={i+1}>
            <li>{el}</li>
            <ul style={{ paddingLeft: "35px", listStyleType: "circle" }}>
              {value[el]?.map((elem: any, ei: number) => (
                <div className="row" key={String(ei) + "_" + String(i)}>
                  
                  
                  <li
                  style={{width: '90%'}}
                  onClick={() => filterUpdateCoaInstrument !== undefined ?
                    filterUpdateCoaInstrument(objName, {Id:elem.Id , Value:elem.Value}) :
                    updateCoaInstrument && updateCoaInstrument(elem.Id)}
                    onMouseEnter={() => {
                      setHoveredIndex(String(ei) + "_" + String(i));
                    }}
                    onMouseLeave={() => {
                      setHoveredIndex("");
                    }}
                  >
                    {hoveredIndex == String(ei) + "_" + String(i) && <span style={{display:"inline",paddingRight:"15px"}}>
                    <CloseIcon
                      
                      style={{
                        color:  String(ei) + "_" + String(i) === hoveredIndex ? "#ff0000" : "#00aae7",

                        transition: "color 0.3s ease",
                        cursor: "pointer",
                      }}
                    />
                  </span>}
                  <span style={{ fontWeight: "bold" }}>{elem.Value.split("||")[0]}</span>
                  {elem.Value.split("||")[1] != undefined && 
                    <span> || {elem.Value.split("||")[1]}</span>
                  }
                  </li>
                </div>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </ul>
    </>
  );
};

const ComplexLookupSplit = ({ data, lookupId }: any) => {
  const value: any = {};
  if (lookupId == LookupIds.SourceOfComparator || 
      lookupId == LookupIds.ClinicalNegatives || 
      lookupId == LookupIds.ClinicalPositives) {
    data.forEach((el: any) => {
      const list = el["Value"].split("~");
      if (value.hasOwnProperty(list[1])) {
        value[list[1]].push(list[0]);
      } else {
        value[list[1]] = [list[0]];
      }
    });
  }
  else {
    data.forEach((el: any) => {
      const list = el["Value"].split("~");
      if (value.hasOwnProperty(list[0])) {
        value[list[0]].push(list[1]);
      } else {
        value[list[0]] = [list[1]];
      }
    });
  }
  
  return (
    <>
      {Object.keys(value)?.map((key) => (
        <React.Fragment key={key}>
          <ul style ={{listStyleType:"disc",paddingLeft : "26px"}}>
            <li>{key}</li>
            <ul style={{paddingLeft : "18px"}}>
              {value[key].map((el: any) => {
                const theObj = { __html: el };
                return (
                  <li
                    style={{ listStyleType: "circle" }}
                    dangerouslySetInnerHTML={theObj}
                  ></li>
                );
              })}
            </ul>
          </ul>
        </React.Fragment>
      ))}
    </>
  );
};


export const getCustomDatasourceName = (id: number) => {
  switch(id){
    case 274:
      return "Subscription";
    case 275:
      return "User";
    case 251:
      return "Client";
    case 252:
      return "Client Group";
    case 253:
      return "Device";
    case 254:
      return "Display Name";
    case 255:
      return "Disease";
    case 256:
      return "Disease Subtype 1";
    case 257:
        return "Disease Subtype 2";
    case 258:
          return "Disease Subtype 3";
    case 259:
          return "Disease Subtype 4";
    case 260:
      return "Therapeutic Area";
    case 261:
        return "Reason";
    case 262:
        return "COA instrument"; 
    case 264:
      return "Field";
    case 265:
      return "Disable export option";
    case 266:
      return ""
    case 267:
      return "Item"
    case 268:
        return "User access report";
    case 269:
      return "Workflow audit report";
    case 270:
        return "dashboard";
    case 272:
      return "Product profile readiness";
    case 271:
      return "User download details"; 
    case 276:
      return "news item"
    case 277:
      return "Show rule";
    default:
      return "Datsource";
  }
} 

export default ComplexLookupSplit;

const datasources = ["HTARecord","ClinicalData","RegulatoryWatch","Pricing","Policy","Agency","Country","Drug","Agreement","TreatmentCost"]

export const SubscriptionFieldCriteria : {[key : string] : string[]} = {
  SuperSearchDiseaseHierIDs : ["HTARecord","ClinicalData","RegulatoryWatch","Agreement","ReimbursementStatus","TreatmentCost"],
  DS_DrugIds : ["HTARecord","ClinicalData","RegulatoryWatch","Pricing","Agreement","ReimbursementStatus","TreatmentCost"],
  DrugGeneric : ["HTARecord","ClinicalData","RegulatoryWatch","Pricing","Agreement","ReimbursementStatus"],
  DecisionStartDate : ["HTARecord"],
  ReimbursementStartDate : ["ReimbursementStatus"],
  PublishStartDate : ["RegulatoryWatch"],
  AgreementInitiationStartDate : ["Agreement"],
  DeviceIDs : ["HTARecord","ClinicalData"],
  DeviceGenericIDs : ["HTARecord","ClinicalData"],
  ProcedureInterventionIDs : ["HTARecord","ClinicalData"],
  DrugClassIDs : ["HTARecord"],
  CountryIDs : ["HTARecord","Policy","Agency","Country"],
  ManufacturerIDs : ["HTARecord","ClinicalData","RegulatoryWatch"],
  RestricAgencyIDs : ["HTARecord","Policy","Agency"],
  RegulatorNames : ["RegulatoryWatch"],
  Pricingcountries : ["Pricing"],
  Reimbursement_Countries : ["ReimbursementStatus"],
  Agreement_Countries : ["Agreement"],
  TreatmentCost_Countries : ["TreatmentCost"]
}

export const SubscriptionMultiselectFields: string[] = [
              "DataSourceIds","SuperSearchDiseaseHierIDs", "DS_DrugIds", "DrugGeneric", "DeviceIDs", 
              "DeviceGenericIDs", "ProcedureInterventionIDs", "DrugClassIDs", "RestricAgencyIDs", 
              "ManufacturerIDs", "RegulatorNames", "Pricingcountries", "Reimbursement_Countries",
               "Agreement_Countries", "TreatmentCost_Countries"
            ]

export const subscriptionDateEndName : {[key:string]:string} = {
  DecisionStartDate : "DecisionEndDate",
  ReimbursementStartDate : "ReimbursementEndDate",
  PublishStartDate : "PublishEndDate",
  AgreementInitiationStartDate : "AgreementInitiationEndDate"
}

export const subscriptionDateKeys = Object.keys(subscriptionDateEndName);

export const diseaseKeys = [
  "DisplayName_ID",
  "TherapeuticArea_ID",
  "Parent_Disease_ID",
  "Parent_SubDiseaseType1_ID",
  "Parent_SubDiseaseType2_ID",
  "Parent_SubDiseaseType3_ID"
]

export const htaRecordContentLimit = {
  Name : 1000,
  Direct_Link : 1000,
  Title : 1000,
  TitleLocalLanguage : 1000,
  Weblink : 1000,
  ICDCode : 250,
  InformationSources : 5000,
  ReportedOutcomeMeasures : 5000,
  EconomicOutcomes : 5000,
  EffectivenessMeasure : 1000,
  TimeHorizon : 1000,
  OSTreatmentOtherComments : 5000,
  PositiveComments : 5000,
  NegativeComments : 5000,
  SpecifyReimbursement : 5000,
  BestReimbursementRate : 200,
  BestSMR : 200,
  BestASMR : 200,
  BestBenefitRatting : 200,
  BestRecommendatioin : 200,
  ReimbursementRateIDs : 1000,
  SMRIDs : 1000,
  ASMRIDs : 1000,
  BenefitRatingIDs : 1000,
  RecommendatioinIDs : 1000
}

export const clinicalDataContentLimit = {
  Name : 1000,
  Direct_Link : 1000,
  Acronym : 255,
  ClinicalTrialsId : 255,
  RegistryLink : 1000,
  PubMedLink : 1000,
  SafetyResults : 5000,
  QolResults : 5000,
  ProResults : 5000,
  MedianTxDuration : 5000
}

export const regulatoryWatchContentLimit = {
  Name : 1000,
  Direct_Link : 1000,
  MarketingAuthHolder : 255,
  ProClaimType : 1000,
  AgencyIdentifier : 1000,
  Weblink : 1000,
  WarningDetails : 5000
}

export const policyContentLimit = {
  Name : 1000,
  RebateDetail: 5000,
  PriceDetails: 5000,
  Scope : 5000,
  Consequence : 5000,
  Timeframe : 255,
  Source : 255,
  FCC : 1000,
  National_Code : 1000,
  ReimbursementStatusSummary : 1000,
  PrescriptionStatus : 200,
  ReimbursementStatus : 200,
  ReimbursementStatusDetails : 500,
  Link_1 : 1000,
  Link_2 : 1000
}

export const agencyContentLimit = {
  Name : 255,
  Direct_Link : 1000,
  Abbreviation : 255,
  NameEnglish : 255,
  Region : 255,
  AgencyLink : 1000,
  AgencyHomepage : 1000,
  LanguageReports : 5000,
  TitleDocument : 5000,
  GuidanceDocLink : 1000,
  RelevantDocLink : 1000,
  AttitudeRealWorldData : 5000,
  AttitudeControlledTrials : 5000,
  IndicationIndication : 5000,
  TargetPopulation : 5000,
  ChoiceComparator : 5000,
  TimeHorizon : 5000,
  AssumptionsRequired : 5000,
  CostsIncluded : 5000,
  SourceCosts : 5000,
  ModelingModeling : 5000,
  DetailRequirements : 5000,
  PreferredMeasure : 5000,
  PreferredUtility : 5000,
  EquityStated : 5000,
  DiscountingCosts : 5000,
  DiscountingOutcomes : 5000,
  SensitivityRange : 5000,
  PresentingResults : 5000,
  GeneralizabilityResults : 5000,
}

export const countryContentLimit = {
  Name : 255,
  Direct_Link : 255,
  CountryList_ID_Text : 255,
  Abbreviation : 3,
  NationalStatisticsOffice : 255,
  StatisticsWebsite : 255,
  GeneralInformation : 255,
  InternalRemark : 255,
  KeySources : 255,
  Instructions : 255,
  Comments : 255,
  PriceDetails : 255,
  RegulatoryBodyAbbr : 255,
  RegulatoryBodyLocalLang : 255,
  RegulatoryBodyEnglish : 255,
  RegulatoryBodyLink : 255,
  RegulatoryAgency : 255,
  ReimbursementAbbr : 255,
  ReimbursementLocalLang : 255,
  ReimbursementEnglish : 255,
  ReimbursementLink : 255,
  ReimbursementAgency : 255,
  PricingBodyAbbr : 255,
  PricingBodyLocalLang : 255,
  PricingBodyEnglish : 255,
  PricingBodyLink : 255,
  PricingBodyAgency : 255,
  HTABody : 255,
  StakeholdersNational : 255,
  StakeholdersRegional : 255,
  StakeholdersLocal : 255
}

export const drugDataContentLimit = {
  Name : 1000,
  Direct_Link : 1000,
}

export const agreementContentLimit = {
  Name : 1000,
  Direct_Link : 1000,
  Custom_Text_Field1 : 250,
  Custom_Text_Field2 : 250,
  Custom_Text_Field3 : 250,
  Custom_Text_Field4 : 250,
  Custom_Text_Field5 : 250
}

export const reimbursementStatusLimit = {
  Name : 255,
  ProductDosing : 255
}

export const clientContentLimit = {
  Name : 255
}

export const clientGroupContentLimit = {
  Name : 255,
  PathwayName : 500
}

export const deviceContentLimit = {
  Name : 255
}

export const diseaseContentLimit = {
  Name : 1000,
  ICD_Code : 50
}

export const therapeuticAreaContentLimit = {
  Name : 50
}

export const workflowReasonsContentLimit = {
  Code : 5,
  Value : 100,
  Status_Ids : 100
}

export const fieldContentLimit = {
  Name : 100,
  Label : 100,
  Display_Name : 255
}

export const lookupContentLimit = {
  Name : 50
}

export const lookupItemContentLimit = {
  Value : 255
}

export const clientNewsContentLimit = {
  Name : 255
}

export const subscriptionContentLimit = {
  Name : 50
}

export const userContentLimit = {
  Identity_Manager_ID : 50,
  Name : 50,
  FirstName : 50,
  LastName : 50,
  Initials : 3,
}
