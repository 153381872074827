import React, { useEffect, useContext, useState, useRef } from "react";
import {DatePicker} from "antd";
import dayjs from 'dayjs';
import {
  Field,
  FieldCriteria,
  Section,
  Comment
} from "../../store/DataSourceMetaData";
import {
  useFetchFieldMutation,
  useFetchFieldCriteriaMutation,
} from "../../store/DataSourceMetaDataApiSlice";
import { EditorState } from "draft-js";
import { useAppSelector } from "../../store/hooks";
import FieldView, { lookupItemT } from "./FieldView";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import SaveIcon from "@mui/icons-material/Save";
import { BiSave } from "react-icons/bi";
import { FaRegRectangleList } from "react-icons/fa6";
import { FaFirstAid } from "react-icons/fa";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ReplayIcon from "@mui/icons-material/Replay";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
//import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import moment from "moment";
import {
  IselectedLookup,
  IselectedLookupGlobe,
  IselectedLookupGlobeSpecial,
} from "../interfaces/multiselect";
import fetchRecord from "../service/fetchRecord";
// import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { IForm, UserContext } from "./Editor";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import PostCall from "../service/postCall";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import UpdateCall from "../service/updateCall";
import { col_items } from "../service/colItems";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import CommentModal from "../Popup/CommentModal";
import { useFetchLookupItemsQuery } from "../../store/LookupApiSlice";
import Comments from "./Comments";
import { RiDeleteBinLine } from "react-icons/ri";
import "./SectionView.css";
import Button from "@mui/material/Button";
import apiCall from "../service/apiCall";
import { getAttachmentInfo, getIdFieldName } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteCall from "../service/deleteCall";
import { Buffer } from "buffer";
import {
  setAuditLogData,
  setColumnFilters,
  setIsSearchGridLoading,
} from "../../store/SearchSlice";
import TableGrid from "../Table/TableGrid";
import { useAppDispatch } from "../../store/hooks";
import {
  setEndpointResultOption,
  showMultiSelectPopup,
  setLookupGlobe,
  setEndpointResultList,
  setSelectedDataState,
  setSelectedLookupGlobe,
  setFieldListAudit,
} from "../../store/MultiSelectSlice";
import { Autocomplete, TextField } from "@mui/material";
import "./form.css";
import "../../app.css";
import PricingView from "../Pricing/PricingView";
import HTARecord_SubGroup from "./SubPages/Views/HTARecord_SubGroup";
import Content from "../AlertBox/ReadMore";
import Policy_PotentialImplications from "./SubPages/Views/Policy_PotentialImplications";
import SubPageViewSummary from "./SubPages/Views/SubPageViewSummary";
import SubPageForm from "./SubPages/Forms/SubPageForm";
import HTARecord_Form_Subgroup from "./SubPages/Forms/HTARecord_Subgroup";
import {
  DataSourceListTypes,
  DefineValidator,
  FormFieldType,
  LookupIds,
  Status,
  UserRoles,
} from "../Shared/Utility";
import DraggablePopover from "../Popups/Popup_MaterialUI";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { ValidateFormData, ValidateFormForSFRandSFRR } from "./ValidateFormSubmission";
import { ToastrAlert } from "../ToastrAlert/ToastrAlert";
import { faL, faXmark } from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import { EIDRM } from "constants";
import { getCustomDatasourceName, INewField, IuserClientRole, SubscriptionFieldCriteria, SubscriptionMultiselectFields } from "./Utility";
import ShowRule from "./ShowRule";

import exportCall from "../service/exportCall";
import AdminReportFormValidation from "./Admin/AdminReportFormValidation";
import Spinner from "react-bootstrap/Spinner";
import CollapseMethodFunctionality from "../Collapse/CollapseMethodFunctionality";
import DynamicFieldForm from "./ShowRule/DynamicField";
import { postAttachement } from "../service/postAttachment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../service/axiosInstance";
import RWE from "./SubPages/Views/HTARecord_RWE";

interface SectionViewProp {
  dataSourceID: number;
  sections: Section[];
  values: IForm;
  fieldChanged: (name?: string, value?: any, obj?: any) => void;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  record_ID: number;
  findComments: Function;
  commentList: Comment[];
  currRole?: number;
  setLookupLabelList: (val: any) => void;
  formMode?: string;
  dynamicField: Field;
}

export const replaceColumnNames = (dsId: number, values: any) => {
  switch(Number(dsId)) {
    case 7:
      if (values['CountryList_ID_Text'] != undefined)
        values['CountryList_ID'] = values['CountryList_ID_Text'];
      delete values['CountryList_ID_Text'];
      break;
    case 20:
      if (values['GlobalBrand_ID_Text'] != undefined)
        values['GlobalBrand_ID'] = values['GlobalBrand_ID_Text'];
      if (values['GlobalCorporation_ID_Text'] != undefined)
        values['GlobalCorporation_ID'] = values['GlobalCorporation_ID_Text'];
      delete values['GlobalBrand_ID_Text'];
      delete values['GlobalCorporation_ID_Text'];
      break;
    case 25:
      // if (values['ReimbursementAuthority_ID'] == values['ReimbursementAuthority_ID_Text'])
      //   delete values['ReimbursementAuthority_ID_Text'];
      // else 
      //   values['ReimbursementAuthority_ID'] = values['ReimbursementAuthority_ID_Text'];

      break;
  }
}

export const AssignMe = (
  dataSourceID: number,
  type: string,
  ID?: number,
  recordId?: number,
  values?: IForm,
  selectedLookupGlobe?: IselectedLookupGlobe,
  selectedLookupGlobeSpecial?: IselectedLookupGlobeSpecial,
  allFields?: any,
) => {
  if (values["Status_ID"] == Status.RC && values["QAssociate"] == null && type == 'AssignQreviewer') {
    ToastrAlert("Please assign associate before assigning reviewer for the record", "warning");
    return false;
  }
  ToastrAlert("Record assignment is in progress", "success");
  replaceColumnNames(dataSourceID, values);
  const [res, requiredFields, updatedValues] = ValidateFormData(dataSourceID, values, allFields);
  PostCall(`/api/update/assignMe`, {
    type: type,
    datasource_id: dataSourceID,
    version_id: ID,
    record_id: recordId,
    form_value: updatedValues,
    selected_ms: selectedLookupGlobe,
    selected_ms_spl: selectedLookupGlobeSpecial,
  }).then((resp) => {
    ToastrAlert("Record assignment done successfully", "info");
    window.location.href = String(
      window.location.protocol +
        "//" +
        window.location.host +
        `/view/${dataSourceID}/${location.pathname.split("/").slice(-1)}`
    );
  });
};

const AssignUser = (
  dataSourceID: number,
  ID?: number,
  recordId?: number,
  values?: IForm,
  selectedLookupGlobe?: IselectedLookupGlobe,
  selectedLookupGlobeSpecial?: IselectedLookupGlobeSpecial,
  associateId?: number,
  reviewerId?: number,
  associateDate?: string,
  reviewerDate?: string,
  comment?: string,
  typeValue?: string,
  is_StatusChange?: string,
  setFormSavingProgress?: Function,
  assignModalClose ?: Function,
  rows?: any,
) => {
  const [res, requiredFields, updatedValues] = ValidateFormData(dataSourceID, values, rows);
  PostCall(`/api/update/assignUser`, {
    datasource_id: dataSourceID,
    version_id: ID,
    record_id: recordId,
    form_value: updatedValues,
    selected_ms: selectedLookupGlobe,
    selected_ms_spl: selectedLookupGlobeSpecial,
    associateId: associateId,
    reviewerId: reviewerId,
    associateDate: associateDate,
    reviewerDate: reviewerDate,
    comment: comment,
    typeValue: typeValue,
    isStatusChange: is_StatusChange,
  }).then((resp) => {
    setFormSavingProgress && setFormSavingProgress(false);
    assignModalClose && assignModalClose();
    ToastrAlert("Record assignment done successfully", "info");
    window.location.href = String(
      window.location.protocol +
        "//" +
        window.location.host +
        `/view/${dataSourceID}/${location.pathname.split("/").slice(-1)}`
    );
  });
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type ClientObj = {
  label: string;
  ID: number;
};
type WorkflowObj = {
  value: string;
  id: number;
};
type AdminObj = {
  label: string;
  ID: number;
};
type AttachmentsObj = {
  fileName: string;
  fileData: string;
  id: number;
  date: string;
};

type DieaseHierachyObj = {
  TherapeuticArea: string;
  Disease: string;
  SubType1: string;
  SubType2: string;
  SubType3: string;
  SubType4: string;
  TherapeuticArea_count: number;
  Disease_count: number;
  SubType1_count: number;
  SubType2_count: number;
  SubType3_count: number;
  SubType4_count: number;
  ICD_Code: string;
  ICD_Code_count: number;
};

export const sizeColor = {
  color: "#00aae7",
  fontSize: "12px",
};

function SectionView(props: SectionViewProp) {
  const {
    dataSourceID,
    sections,
    values,
    fieldChanged,
    RichtextFieldChanged,
    record_ID,
    findComments,
    commentList,
    currRole,
    setLookupLabelList,
    formMode,
    dynamicField
  } = props;
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const selectedLookupGlobeDbVal = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeDbVal
  );
  const fieldListAudit = useAppSelector(
    (state) => state.multiselect.fieldListAudit
  );
  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );
  const lookupSource = useAppSelector(
    (state) => state.multiselect.lookupSource
  );
  const accountid = useAppSelector((state) => state.userProfile.accountId);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [commentModalView, setCommentModalViewl] = useState<string>("");
  const [clientList, setClientList] = useState<ClientObj[]>([]);
  const myRef = useRef();
  const [currentView, setCurrentView] = useState<String>("Record");
  const [attachmentList, setAttachmentList] = useState<AttachmentsObj[]>([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const auditData = useAppSelector((state) => state.search.auditData);
  const [diseaseHierarchyOne, setDieaseHierarchyOne] = useState<
    DieaseHierachyObj[]
  >([]);
  const [diseaseHierarchyTwo, setDieaseHierarchyTwo] = useState<
    DieaseHierachyObj[]
  >([]);
  const [diseaseTableOne, setDiseaseTableOne] = useState<any>({});
  const [diseaseTableTwo, setDiseaseTableTwo] = useState<any>({});
  const [showSecondDiseaseHierarchy, setShowSecondDiseaseHierarchy] =
    useState<boolean>(false);
  const endPointResultOption = useAppSelector((state) =>  state.multiselect.endpointResultOption);
  const endpointResultList = useAppSelector((state) =>  state.multiselect.endpointResultList)
  const [selectedEndpointResult, setSelectedEndpointResult] = useState([]);
  const [openEndpointResult, setOpenEndpointResult] = useState<boolean>(false);
  const [isMenuHidden, setIsMenuHidden] = useState<boolean>(false);
  const [attachment_DB_ID, setAttachment_DB_ID] = useState<number>(0);
  const [compareWithValue, setCompareWithValue] = useState<string>("");
  const [pricingCompareRecord, setPricingCompareRecord] = useState({});
  const [mandoryFieldMissing, setMandoryFieldMissing] =
    useState<boolean>(false);
  const isSearchgGridLoading = useAppSelector(
    (state) => state.search.isSearchgGridLoading
  );

  // fetch(process.env.REACT_APP_API_URL + 'api')
  const params : any = useParams()
  const location : any = useLocation();
  
  const isViewVersion = location.pathname.includes("ViewVersion");
  const versionID = isViewVersion ? params.versionID : undefined;
  const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  // const [formMode, setFormMode] = useState<string>("");
  const currRoleId = localStorage.getItem("CurrRoleId");
  const [prcingTitle, setPricingTitle] = useState<string>("pricing");
  const [regulatoryTitle, setRegulatoryTitle] = useState<string>("Regulatory");
  const [drugDataTitle, setDrugDataTitle] = useState<string>("Drug");
  const [openRecordCreated, setOpenRecordCreated] = React.useState(false);
  const [nextStatusId, setNextStatusId] = useState<number>(0);
  const [minuteHourValidation, setMinuteHourValidation] = useState<string>("");
  const [workLogMinute, setWorkLogMinute] = useState<number>(0);
  const [workLogHour, setWorkLogHour] = useState<number>(0);
  const [workflowListAll, setWorkflowListAll] = useState<any[]>([]);
  const [workflowList, setWorkflowList] = useState<WorkflowObj[]>([]);
  const userContext = useContext(UserContext);
  const setConditionalFormFields = userContext.setConditionalFormFields;
  const [selectedWorkflowReason, setSelectedWorkflowReason] =
    useState<number>(null);
  const [workflowMessage, setWorkflowMessage] = useState<string>("");
  const [userList, setUserList] = useState<AdminObj[]>([]);
  const [userOpenAssociate, setUserOpenAssociate] = React.useState(false);
  const [userOpenReviewer, setUserOpenReviewer] = React.useState(false);
  const [selectedAssociate, setSelectedAssociate] = useState<AdminObj>({
    ID: 0,
    label: "",
  });
  const [selectedReviewer, setSelectedReviewer] = useState<AdminObj>({
    ID: 0,
    label: "",
  });
  const [associateDueDate, setAssociateDueDate] = useState<string>();
  const [reviewerDueDate, setReviewerDueDate] = useState<string>();
  const [typeValue, setTypeValue] = React.useState("1");
  const [assignmentValidation, setAssignmentValidation] = useState<string>("");
  const [selectedAssociateName, setSelectedAssociateName] =
    useState<string>("");
  const [selectedReviewerName, setSelectedReviewerName] = useState<string>("");
  const [enteredComment, setEnteredComment] = useState<string>("");

  const [disableState, setDisableState] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showPrePopulatedFieldsModal, setShowPrePopulatedFieldsModal] =
  React.useState<HTMLButtonElement | null>(null);
  const assignModalClose = () => {
    setShowAssignModal(false);
    setSelectedAssociate({
      ID: 0,
      label: "",
    });
    setSelectedReviewer({
      ID: 0,
      label: "",
    });
    setAssociateDueDate(undefined);
    setReviewerDueDate(undefined);
    setAssignmentValidation('');
  }
  const [fieldCriteriaFieldId,setFieldCriteriaFieldId] = useState<number>(Number(params.fieldCriteriaID));

  const [show,setShow] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState("");

  const [rows, setRows] = React.useState<Field[]>([]);
  const [newRows, setNewRows] = useState<INewField>({});
  const [fieldCriterias, setFieldCriterias] = React.useState<FieldCriteria[]>(
    []
  );
  const [lookupDisplay, setLookupDisplay] = useState({});
  const [nonVersionCol1, setNonVersionCol1] = useState<string>("");
  const [nonVersionCol2, setNonVersionCol2] = useState<string>("");
  const user_id = sessionStorage.getItem("userid");
  const [userClientRole, setuserClientRole] = useState<IuserClientRole[]>([]);
  const [buttonName, setButtonName] = useState<string>("");
  const [formSavingProgress, setFormSavingProgress] = useState<boolean>(false);
  const [editOnProgress, setEditOnProgress] = useState<boolean>(false);
  const [files,setFiles] = useState<any[]>([]);
  const [uploadedFiles,setuploadedFiles] = useState<any[]>([]);
  const [clientNewsFileId, setClientNewsFileId] = useState<number>(0);


  const readFile = (file : any) => {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader()
  
      // Register event listeners
      reader.addEventListener("loadend", e => resolve(e.target.result))
      reader.addEventListener("error", reject)
  
      // Read file
      reader.readAsArrayBuffer(file)
    })
  }
  const getAsByteArray = async(file  : any) => {
    return new Uint8Array(await readFile(file));
  }

  const handleChangeForFile = async(e:any) => {
    const file = e.target.files[0];
    setFiles([...files,file]);
    const byteFile =  await getAsByteArray(file);
    let _uploadedFiles = [...uploadedFiles || []]
    const _uploadedFiledObj = {
      ContentType : file.type,
      FileData : {
        data : byteFile,
        type : "Buffer"
      },
      FileName : file.name,
      NewlyAdded : true
    }
    let lastNewlyAddedIndex = 0;
    _uploadedFiles.forEach((el,i) => {
      if(el.NewlyAdded)
        lastNewlyAddedIndex = i + 1
    });
    _uploadedFiles.splice(lastNewlyAddedIndex,0,_uploadedFiledObj)
    setuploadedFiles(_uploadedFiles);

   
  }


  const deleteAttachementFile = async() =>{
      await DeleteCall(`/api/clientnews/delete/attachment/${clientNewsFileId}`);
      window.location.reload();
  }

  const deleteClientNews = async() =>{
    await DeleteCall(`/api/clientnews/delete/${record_ID}`);
    navigate.push('/news/list');
}

  const fetchClientNewsFiles = async() => {
    const res  : any = await apiCall(`/api/clientnews/files/${record_ID}`);
    if(res.status == 200)
      setuploadedFiles(res.data);
  }
  useEffect(() => {
    if(dataSourceID == 276 && record_ID)
      fetchClientNewsFiles()
  },[record_ID])

  let isEClient = Number(currRoleId) > 4 && Number(currRoleId) < 8;

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleRecordCreate = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenRecordCreated(false);
  };
  const fetchUsers = useFetchLookupItemsQuery({
    lookup_id: 301,
    datasource_id: dataSourceID,
  });

  const handleCloseForPrepopulatedFields = () => {
    setShowPrePopulatedFieldsModal(null);
  };

  let datasourceName = "HTA";
  let datasourceDbName = "";
  let commentTableName = "";

  const createDate = (value: any) => {
    if(value == null)
      return undefined;
    let day = value.$D;
    let month = value.$M + 1;
    let year = value.$y;
    let date = `${month}-${day}-${year}`;
    return date;

  };

  switch (Number(dataSourceID)) {
    case 1:
      datasourceName = "HTA";
      datasourceDbName = "HTARecord";
      commentTableName = "HTArecord_comments";
      break;
    case 2:
      datasourceName = "Clinical";
      datasourceDbName = "ClinicalData";
      commentTableName = "ClinicalData_Comments";
      break;
    case 3:
      datasourceName = "Regulatory";
      datasourceDbName = "RegulatoryWatch";
      commentTableName = "RegulatoryWatch_Comments";
      break;
    case 4:
      datasourceName = "Pricing";
      datasourceDbName = "Pricing";
      commentTableName = "Pricing_Comments";
      break;
    case 5:
      datasourceName = "Policy";
      datasourceDbName = "Policy";
      commentTableName = "Policy_Comments";
      break;
    case 6:
      datasourceName = "Agency";
      datasourceDbName = "Agency";
      commentTableName = "Agency_Comments";
      break;
    case 7:
      datasourceName = "Country";
      datasourceDbName = "Country";
      commentTableName = "Country_Comments";
      break;
    case 20:
      datasourceName = "Drug";
      datasourceDbName = "Drug";
      commentTableName = "DrugData_Comments";
      break;
    case 23:
      datasourceName = "Agreement";
      datasourceDbName = "Agreement";
      commentTableName = "Agreement_Comments";
      break;
    case 25:
      datasourceName = "Reimbursement status";
      datasourceDbName = "ReimbursementStatus";
      commentTableName = "ReimbursementStatus_Comments";
      break;
    case 27:
      datasourceName = "Treatment cost";
      datasourceDbName = "RegulatoryWatch";
      break;
    case 251:
      datasourceName = "Client";
      break;
    case 252:
      datasourceName = "Client group";
      break;
    case 253:
      datasourceName = "Device";
      break;
    case 254:
      datasourceName = "Disease Display Name";
      break;
    case 255:
      datasourceName = "Disease";
      break;
    case 256:
      datasourceName = "Disease sub type1";
      break;
    case 257:
      datasourceName = "Disease sub type2";
      break;
    case 258:
      datasourceName = "Disease sub type3";
      break;
    case 259:
      datasourceName = "Disease sub type4";
      break;
    case 260:
      datasourceName = "TherapeuticArea";
      break;
    case 261:
      datasourceName = "Workflow reasons";
      break;
    case 262:
      datasourceName = "COA Instrument";
      break;
    case 264:
      datasourceName = "Field Management";
      break;
    case 265:
      datasourceName = "Disable export option";
      break;
    case 266:
      datasourceName = "Lookup lists";
      break;
    case 267:
      datasourceName = "Lookup Items";
      break;
    case 268:
      datasourceName = "User access log";
      break;
    case 269:
      datasourceName = "Workflow audit logs";
      break;
    case 270:
      datasourceName = "Dashboards";
      break;
    case 271:
      datasourceName = "User download reports";
      break;
    case 272:
      datasourceName = "Product profile readiness";
      break;
    case 273:
      datasourceName = "Manage saved searches";
      break;
    case 274:
      datasourceName = "Subscription";
      break;
    case 275:
      datasourceName = "Manage users";
      break;
    case 277:
      datasourceName = "Show Rule";
      break;
  }

  useEffect(() => {
    findComments(commentTableName);
  }, [commentTableName]);

  useEffect(() => {
    document.title = datasourceName + " - IQVIA HTAA";
  }, [dataSourceID]);


  const masterFieldTypes = [
    30, 40, 42, 43, 49, 50, 51, 53, 54, 57, 61, 62, 63, 64, 66, 68, 72, 73,
  ];

  let nextStatus = 0;
  let isReports = false;
  let isAdminPage = false;
  let title =
  dataSourceID > 250 ? "" :values["Name"] == null || values["Name"].trim() == ""
      ? "Create new " +
        (datasourceName == "HTA"
          ? datasourceName
          : datasourceName.toLowerCase()) +
        " record"
      :  values["Name"];
  let summaryOrConclusionText =
    dataSourceID == DataSourceListTypes.HTARecord && values["Tagline"] != null ? ( <Content txt={values["Tagline"]} /> ) : dataSourceID == DataSourceListTypes.ClinicalData &&
      values["Conclusion"] != null &&
      values["Conclusion"] != undefined ? ( <Content txt={values["Conclusion"]} /> ) 
      : null;
  let summaryConclusionLabel =
    dataSourceID == DataSourceListTypes.HTARecord && values["Tagline"] != null
      ? "Executive Summary - "
      : dataSourceID == DataSourceListTypes.ClinicalData &&
        values["Conclusion"] != null &&
        values["Conclusion"] != undefined
      ? "Conclusion - "
      : null;

  if (dataSourceID == DataSourceListTypes.Pricing) {
    title = prcingTitle;
  }
  if (
    dataSourceID == DataSourceListTypes.RegulatoryWatch &&
    values &&
    values["RegulatoryWatch_ID"] != null &&
    values["RegulatoryWatch_ID"] != undefined
  ) {
    title = regulatoryTitle;
  }
  if (
    dataSourceID == DataSourceListTypes.DrugData &&
    values &&
    values["DrugData_ID"] != null &&
    values["DrugData_ID"] != undefined
  ) {
    title = drugDataTitle;
  }

  if (dataSourceID >= 268 && dataSourceID <= 272 && dataSourceID != 270) {
    title = datasourceName;
    isReports = true;
  }
  if (dataSourceID > 250) {
    isAdminPage = true;
  }

  let hashPrefix =
    location.pathname.split("/")[1] +
    "/" +
    dataSourceID.toString() + 
    (isNaN(record_ID) ? 
    '' :
    "/" +
    record_ID.toString()) +
    (isNaN(versionID) ? '' : '/' + versionID.toString());
    
  const [fetchField, { isLoading }] = useFetchFieldMutation();

  const GetFieldData = async (payload: any) => {
    const result: any = await fetchField(payload);

    if (result && result.data) {
      let dsFields: Field[];
      if (Number(currRole) > 4 && Number(currRole) < 8) {
        dsFields = result.data.filter((f: any) => f.externallyvisible == true);
      } else {
        dsFields = result.data;
        dsFields = dsFields.map((el) =>
          el.name == "PrimaryAutocompelete_ID" ||
          el.name == "SecondaryAutocompelete_ID"
            ? { ...el, fieldTypeId: 7 }
            : el
        );
        dsFields = dsFields.map((el) =>
          el.lookupId == LookupIds.DS_DrugGeneric && 
          [DataSourceListTypes.HTARecord, DataSourceListTypes.ClinicalData, DataSourceListTypes.RegulatoryWatch].includes(dataSourceID)
            ? { ...el, lookupId: LookupIds.DS_DrugGenericNonReviewed }
            : el
        );
      }
      if (dataSourceID == 25)
        dsFields = dsFields.filter(
          (x) => x.name != "VW_ReimbursementStatus_TA"
        );

      // else if(dataSourceID == 275 )
      //   dsFields = dsFields.filter(
      //   (x) => x.name != "Lookup_Type"
      // );

      else if(dataSourceID == 275 && record_ID )
      dsFields = dsFields.filter(
      (x) => x.name != "IQVIANetworkID" && x.name != "Lookup_Type" 
    );
    else if(dataSourceID == 251 )
      dsFields = dsFields.filter(
    (x) => x.name != "Selected_Client_Groups")
    else if(dataSourceID == 31)
      dsFields = dsFields.filter(
      (x) => x.fieldTypeId != 19)

    let _newRows : INewField = {};
    dsFields.forEach((field) => {
      if( _newRows[field.sectionId])
      _newRows[field.sectionId].push(field);
      else
        _newRows[field.sectionId] = [field];
    })
    setNewRows(_newRows);
    setRows(dsFields);
      // if (sections && sections.length > 0) setValue(sections[0].id.toString());
    }


  };

  const getFieldCriteriaData = async (payload:any)=>{
    const criteria: any = await fieldCriteria(payload);
    if (criteria && criteria.data) {
      setFieldCriterias(criteria.data);
    }
  }

  useEffect(() => {
    GetFieldData({ dataSourceID: dataSourceID });
    getFieldCriteriaData({ dataSourceID: dataSourceID })
  }, []);

  useEffect(() => {
    if (
      dataSourceID == DataSourceListTypes.Pricing &&
      // values &&
      // values["Pricing_ID"] != null &&
      // values["Pricing_ID"] != undefined
      record_ID != null && 
      !isNaN(record_ID)
    ) {
      apiCall(`/api/pricing/name/${record_ID}`).then((resp) => {
        setPricingTitle(
          resp.data["Drug_Name"] +
            " - " +
            resp.data["Country"] +
            " - " +
            resp.data["DosePerUnit"] +
            " " +
            resp.data["Unit_Dose"] +
            " | " +
            resp.data["PackSize"] +
            " " +
            resp.data["Unit_Pack"]
        );
      });
    }
    if (
      dataSourceID == DataSourceListTypes.RegulatoryWatch &&
      // values &&
      // values["RegulatoryWatch_ID"] != null &&
      // values["RegulatoryWatch_ID"] != undefined
      record_ID != null && 
      !isNaN(record_ID)
    ) {
      apiCall(`/api/regulatory/name/${record_ID}`).then((resp) => {
        setRegulatoryTitle(
          resp.data["DrugName"] + " | " + resp.data["RegulatorLIValue"]
        );
      });
    }
    if (
      dataSourceID == DataSourceListTypes.DrugData && 
      record_ID != null && 
      !isNaN(record_ID)
    ) {
      apiCall(`/api/drugData/name/${record_ID}`).then((resp) => {
        setDrugDataTitle(resp.data["Name"]);
      });
    }
  }, []);



  const [fieldCriteria, { error: errorFC, isLoading: isLoadingFC }] =
    useFetchFieldCriteriaMutation();


  const fetchClients = useFetchLookupItemsQuery({
    lookup_id: 127,
    datasource_id: dataSourceID,
  });

  useEffect(() => {
    if (fetchClients.data && fetchClients.data.length > 0) {
      let arr = [];
      for (let c of fetchClients.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
        });
      }
      setClientList(arr);
    }
  }, [fetchClients.isLoading]);

  useEffect(() => {
    if (fetchUsers.data && fetchUsers.data.length > 0) {
      let arr = [];
      for (let c of fetchUsers.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
        });
      }
      setUserList(arr);
    }
  }, [fetchUsers.isLoading]);

  const renderOptions = (
    props: React.HTMLAttributes<HTMLElement>,
    option: Partial<any>
  ) => {
    return (
      <li {...props} key={option.ID}>
        {option.label}
      </li>
    );
  };

  const handleAssignType = (event: React.SyntheticEvent, newValue: string) => {
    setTypeValue(newValue);
    setAssignmentValidation('');
  };

  const [value, setValue] = useState("47");

  const createNewVersion = async(
    dataSourceID: number,
    recordId: number,
    worklogAssociate?: number,
    worklogReviewer?: number,
    formValues?: IForm,
    newVersionOnEdit?: boolean
  ) => {
    if (recordId == 0) {
      let itemToCheck: any;
      if (dataSourceID == DataSourceListTypes.DrugData) {
        itemToCheck = values["GlobalBrand_ID"] || values["GlobalBrand_ID_Text"];
        const resp = await apiCall(`/api/isAlreadyExists/${dataSourceID}/${itemToCheck}`);
        if (resp.data == true) {
          setFormSavingProgress(false);
          ToastrAlert("Brand already exists", "error");
          return;
        } else {
          await insertVersion(
            dataSourceID,
            recordId,
            worklogAssociate,
            worklogReviewer,
            false,
            formValues
          );
        }
      } else {
        await insertVersion(
          dataSourceID,
          recordId,
          worklogAssociate,
          worklogReviewer,
          false,
          formValues
        );
      }
    } else {
      await insertVersion(
        dataSourceID, 
        recordId, 
        worklogAssociate, 
        worklogReviewer, 
        true, 
        formValues, 
        newVersionOnEdit
      );
    }
  };

  const updateFieldListAudit = (fieldList: string) => {
    let audit_field = values['Audit_Field_Name'] ?? '';
    for (const[key] of Object.entries(selectedLookupGlobe)) {
      if (audit_field != '' && audit_field != null && audit_field.split(',').indexOf(key) == -1) {
        if (key.indexOf('Autocompelete_ID') == -1 && 
        (selectedLookupGlobeDbVal[key] == undefined ||
          !(selectedLookupGlobe[key].every(element => selectedLookupGlobeDbVal[key].some(({Id}) => Id === element.Id)))
        )) {
          audit_field += key + ",";
        }
      }
    }
    for (let x of fieldList.split(',')) {
      if (audit_field != '' && audit_field != null && audit_field.split(',').indexOf(x) == -1) {
        audit_field += x + ",";
      }
    }
    return audit_field;
  }

  const insertFieldListAudit = (fieldList: string, formValues: any) => {
    if (formValues['Status_ID'] == Status.WIP)
      fieldList += 'Status_ID,';
    for (const[key] of Object.entries(selectedLookupGlobe)) {
      if (key.indexOf('Autocompelete_ID') == -1 && 
      (selectedLookupGlobeDbVal[key] == undefined ||
        !(selectedLookupGlobe[key].every(element => selectedLookupGlobeDbVal[key].some(({Id}) => Id === element.Id)))
      )) {
        fieldList += key + ",";
      }
    }
    return fieldList;
  }

  const updateVersion = async(dataSourceID: number, recordId: number, updatedValues: any) => {
    const values = updatedValues;
    const fieldListAuditUpdated = updateFieldListAudit(fieldListAudit);
    dispatch(setFieldListAudit(fieldListAuditUpdated));
    await UpdateCall(`/api/update/versionRecord/${dataSourceID}`, recordId, {
      user_id,
      values,
      selectedLookupGlobe,
      selectedLookupGlobeSpecial,
      fieldListAuditUpdated,
    });
    setFormSavingProgress(false);
    ToastrAlert("Data saved successfully", "info");
    window.location.href = String(
      window.location.protocol +
        "//" +
        window.location.host +
        `/form/${dataSourceID}/${recordId}`
    );
  };

  useEffect(() => {
    if(dataSourceID == 275 && formMode == "write" && record_ID){
      (async() => {
        const res = await apiCall(`/api/userclientrole/${record_ID}`);
        const data : IuserClientRole[] = res.data;
        setuserClientRole(data);
      })()
    }
  },[dataSourceID,record_ID,formMode])

  const insertVersion = async (
    dataSourceID: number,
    recordId: number,
    worklogAssociate?: number,
    worklogReviewer?: number,
    workflowChangeStatus?: boolean,
    formValues?: IForm,
    newVersionOnEdit?: boolean
  ) => {
    replaceColumnNames(dataSourceID, formValues);
    const fieldListAuditUpdated = insertFieldListAudit(fieldListAudit, formValues);
    dispatch(setFieldListAudit(fieldListAuditUpdated));
    const resp = await PostCall(`/api/insert/versionRecord/${dataSourceID}/${recordId}`, {
      user_id,
      formValues,
      selectedLookupGlobe,
      selectedLookupGlobeSpecial,
      fieldListAuditUpdated,
      worklogAssociate,
      worklogReviewer,
      selectedWorkflowReason,
      workflowMessage,
    })
    try {
      setFormSavingProgress(false);
      if (newVersionOnEdit) {
        setEditOnProgress(false);
        window.location.href = String(
          window.location.protocol +
          "//" +
          window.location.host +
          `/form/${dataSourceID}/${resp.data}`
        );
      } else {
        ToastrAlert("Data saved successfully", "info");
        // alert("Data saved successfully");
        window.location.href = String(
          window.location.protocol +
          "//" +
          window.location.host +
          `/view/${dataSourceID}/${resp.data}`
        );
      }
    }
    catch (error: any) {
      console.log("Error", error);
    };
  };

  const AdminPageRedirect = (createNew?:boolean) => {
    if(dataSourceID == 277 && createNew){
      window.location.href = `/admin/fieldcriteria/277/${params.fieldCriteriaDatasourceID}/${params.fieldCriteriaID}`;
    }
    else if (dataSourceID == 277)
      navigate.push(`/admin/form/264/${fieldCriteriaFieldId}`);
    else if (dataSourceID == 276)
      navigate.push(`/news/list`);
    else if (dataSourceID == 252)
      navigate.push(`/admin/list/${dataSourceID}/${values["Client_ID"]}`);
    else if (dataSourceID == 267)
      navigate.push(`/admin/list/${dataSourceID}/${values.Lookup_ID}`);
    else if(dataSourceID == 264)
      navigate.push(`/admin/list/${dataSourceID}/${values.DatasourceId}`);    
    else if(dataSourceID == 275)
    window.location.href = `/admin/list/${dataSourceID}`;
    else
      navigate.push(`/admin/list/${dataSourceID}`);
  }

  const exterrnalRoleCheck = (values : any) => {
    if((Number(values.Role_ID) == UserRoles.EClient|| Number(values.Role_ID) == UserRoles.EDataEntry 
    || Number(values.Role_ID) == UserRoles.EExpert) && (!selectedLookupGlobe.Client_Group || selectedLookupGlobe.Client_Group?.length == 0)){
      setConditionalFormFields({Client_Group : "When adding a user to an External role, they must be assigned a client group."})
      setDisableState(false);
      setFormSavingProgress(false);
      return false
    }
    return true
  }


  const formSave = async (createNew?: boolean) => {
    if(dataSourceID == 2 && values.ClinicalTrialsId && formMode == "new"){
      const resp : any = await apiCall(`/api/aact/isExist/${values.ClinicalTrialsId}`);
      const nctExist = resp.data
      if(nctExist){
        ToastrAlert("This NCT already exists in the system", "error");
        return 
      }
    }
    if (disableState === true) {
      return;
    } else {
      setFormSavingProgress(true);
      setDisableState(true);
      // if(datasourceId ==268){
      //   console.log("export ");
      // }

      if (dataSourceID === 268) {
        const [check, requiredFields] = AdminReportFormValidation(values, dataSourceID);
        setConditionalFormFields(requiredFields);
        if (check)
          await exportCall(`/api/export/useraccess`, values, 'UserAccessLog.xlsx');

      } else if (dataSourceID == 269) {
        const [check, requiredFields] = AdminReportFormValidation(values, dataSourceID);
        setConditionalFormFields(requiredFields);
        if (check)
          await exportCall(`/api/export/workflowaudit`, values, 'WorkflowAuditLog.xlsx');

      }
      else if (dataSourceID == 271) {
        const [check, requiredFields] = AdminReportFormValidation(values, dataSourceID);
        setConditionalFormFields(requiredFields);
        if (check) {
          values.selectedLookupGlobe = selectedLookupGlobe;
          await exportCall(`/api/export/userDownloadDetails`, values, 'Userdownloaddetails.xlsx');
        }
      }
      else if (dataSourceID == 272) {
        await exportCall(`/api/export/productprofile`, values, 'Userdownloaddetails.xlsx');
      }
      else if (dataSourceID > 250) {
        const [res, requiredFields, updatedValues] = ValidateFormData(dataSourceID, values, rows);
        if(!res){
          ToastrAlert("One of the form fields is missing or invalid", "warning");
          setConditionalFormFields(requiredFields);
          setFormSavingProgress(false);
          
        }else{
          if ("ID" in values || "Id" in values) {
            let _selectedLookupGlobe = { ...selectedLookupGlobe };
            if (dataSourceID == 274) {
              const datasources = selectedLookupGlobe.DataSourceIds.map((x) => x.Value);
              const keys = Object.keys(SubscriptionFieldCriteria);
              keys.forEach((key) => {
                if (!SubscriptionFieldCriteria[key].some(x => datasources.includes(x))) {
                  if (SubscriptionMultiselectFields.includes(key)) {
                    _selectedLookupGlobe[key] = [];
                  } else
                    delete values[key];
                }
              });
            }
            if (dataSourceID == 275) {
              if (!exterrnalRoleCheck(values))
                return
            }
  
            const res = await UpdateCall(`/api/admin/update/${dataSourceID}`, values["ID"] || values["Id"], {
              user_id,
              values,
              _selectedLookupGlobe,
            });
            if(dataSourceID == 276 && res.status == 200 && res.data)
            {
              const _datavalues = new FormData();
              files.forEach((el) => {
                _datavalues.append("files",el);
              });
              await postAttachement(`/api/clientnews/uploadFile/${values["ID"] || values["Id"]}`,_datavalues);
            }
  
            if (dataSourceID == 275) {
              if (res?.data?.isUserTableChanged) {
                sessionStorage.clear();
                localStorage.clear();
              }
            }
            if (res.name == "AxiosError") {
              if (res.response.status == 409) {
                console.log(res.response);
                const key = Object.keys(res.response.data)[0];
                setConditionalFormFields({ [key]: res.response.data[key] });
              } else
                alert(res)
              setDisableState(false);
              setFormSavingProgress(false);
              return;
            }
            AdminPageRedirect(createNew);
          } else {
            if(dataSourceID == 276){
              
              const res = await PostCall(`/api/admin/create/${dataSourceID}`, {
                values,
                selectedLookupGlobe,
              })
              if(res.status == 201 && res.data)
                {
                  const _datavalues = new FormData();
                  files.forEach((el) => {
                    _datavalues.append("files",el);
                  });
                  await postAttachement(`/api/clientnews/uploadFile/${res.data}`,_datavalues);
                }
                else{
                  setDisableState(false);
                  setFormSavingProgress(false);
                  return 
                }
            }else{
              try {
                if (dataSourceID == 252)
                  values["Client_ID"] = params.clientID;
                else if (dataSourceID == 267)
                  values.Lookup_ID = params.lookupID;
                else if (dataSourceID == 274) {
                  const datasources = selectedLookupGlobe.DataSourceIds.map((x) => x.Value);
                  const keys = Object.keys(SubscriptionFieldCriteria);
                  keys.forEach((key) => {
                    if (!SubscriptionFieldCriteria[key].some(x => datasources.includes(x))) {
                      delete values.key;
                      delete selectedLookupGlobe[key];
                    }
                  });
                }
                if (dataSourceID == 275) {
                  if (!exterrnalRoleCheck(values))
                    return
                }
                await PostCall(`/api/admin/create/${dataSourceID}`, {
                  values,
                  selectedLookupGlobe,
                })
              } catch (error: any) {
                if (dataSourceID == 275) {
                  if (error.response.status == 409) {
                    console.log(error.response);
                    const key = Object.keys(error.response.data)[0];
                    setConditionalFormFields({ [key]: error.response.data[key] });
                  }
                } else
                  alert(error)
                setDisableState(false);
                setFormSavingProgress(false);
                return;
              }
            }
  
  
            AdminPageRedirect(createNew);
            //   window.location.href = String(
            //   window.location.protocol +
            //     "//" +
            //     window.location.host +
            //     `/admin/list/${dataSourceID}`
            // );
          }
        }
      } else {
        const [res, requiredFields, updatedValues] = ValidateFormData(dataSourceID, values, rows);
        if(!res){
          ToastrAlert("One of the form fields is missing or invalid", "warning");
          setConditionalFormFields(requiredFields);
          setFormSavingProgress(false);
          
        }else{
          if (dataSourceID == 31){
            const res : any = await PostCall('/api/assumption',values);
            if(res.status == 201)
              window.location.reload();
          }else{
            if (col_items[dataSourceID] in values) {
              let _values = { ...updatedValues };
              const nextStatus = Number(
                GetNextStatus(String(values["Status_ID"]), "WIP")
              );
              if (
                String(values["Status_ID"]) == String(Status.A) ||
                String(values["Status_ID"]) == String(Status.AR)
              ) {
                _values = { ..._values, ['Status_ID']: nextStatus };
                await createNewVersion(
                  dataSourceID,
                  values[col_items[dataSourceID]],
                  0,
                  0,
                  _values
                );
                // values["Status_ID"] = nextStatus;
                // createNewVersion(
                //     dataSourceID,
                //     values[col_items[dataSourceID]],
                //     0,
                //     0
                //   );
              } else {
                await updateVersion(dataSourceID, values[col_items[dataSourceID]], updatedValues);
              }
            } else {
              await createNewVersion(dataSourceID, 0, 0, 0, updatedValues);
              // }
            }
          }

        }


      }
      setDisableState(false);
      setFormSavingProgress(false);
    }
  };

  const [showEmptyFieldsFlag, setShowEmptyFieldsFlag] =
    useState<boolean>(false);

  const [anchorRecDel, setAnchorRecDel] =
    React.useState<HTMLButtonElement | null>(null);

  const [attachmentDel, setAttachmentDel] =
    React.useState<HTMLButtonElement | null>(null);

  const [clientNewsDel, setClientNewsDel] = 
    React.useState<HTMLButtonElement | null>(null);
  
  const [disableExportDel, setDisableExportDel] =
    React.useState<HTMLButtonElement | null>(null);

  const [anchorWorkflowChange, setAnchorWorkflowChange] =
    React.useState<HTMLButtonElement | null>(null);

  const [anchorAssign, setAnchorAssign] =
    React.useState<HTMLButtonElement | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const showHideEmptyFields = () => {
    setShowEmptyFieldsFlag(!showEmptyFieldsFlag);
  };

  const getHierarchyLeafName = (name: string): string => {
    if (name.indexOf(".") < 0) return name;

    let lastPeriodIndex = name.lastIndexOf(".");
    let masterName = name.substring(0, lastPeriodIndex);
    let detailName = name.substring(lastPeriodIndex + 1);

    return detailName;
  };

  async function openModal(ID: number, Name: string, title: string) {
    // dispatch(setModalShow(true))
    const _token = sessionStorage.getItem("token");
    // const lookupKey = String(ID) + '_' + Name
    const lookupKey = Name;
    if (!lookupGlobe.hasOwnProperty(lookupKey)) {
      let newLookupGlobe = fetchRecord(ID, dataSourceID);
      newLookupGlobe.then((result) => {
        let newlookupGlobe = { ...lookupGlobe, [lookupKey]: result };
        dispatch(setLookupGlobe(newlookupGlobe));
      });
    }
    dispatch(showMultiSelectPopup(ID, true, lookupKey, title));
  }

  const endPointDisplay = (value: any, object: any) => {
    if (value.name === "COA_Endpoints.EndpointType") {
      openModal(363, "COA_Endpoints.EndpointType", "Endpoint Type");
      object.push({});
      setOpenEndpointResult(true);
    } else object.push({});
  };

  const endpointResultSelection = (id: any) => (e: any, value: any) => {
    if (value.length > 0) {
      setSelectedEndpointResult(value);
      let tmp = [...endPointResultOption];
      let index = tmp.findIndex((item) => item.id === value[0].id);
      if (index !== -1) {
        tmp.splice(index, 1);
        dispatch(setEndpointResultOption(tmp));
      }
    }
  };

  const deleteEndpoint = (item: any) => {
    let tmp = [...endpointResultList];
    let index = tmp.findIndex((x) => x.Id === item.Id);
    if (index !== -1) {
      tmp.splice(index, 1);
      dispatch(setEndpointResultList(tmp));
    }
  };

  const deleteResult = (item: any) => {
    let tmp1 = [...endPointResultOption];
    tmp1.push(item);
    dispatch(setEndpointResultOption(tmp1));
    let tmp2 = [...selectedEndpointResult];
    let index = tmp2.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      tmp2.splice(index, 1);
      setSelectedEndpointResult(tmp2);
    }
  };

  const UpdateLookupField = (name: string, value: string) => {
    setLookupDisplay({ ...lookupDisplay, [name]: value });
  };

  const UpdateNonVersionCol1 = (value: string) => {
    if (dataSourceID == DataSourceListTypes.ReimbursementStatus) {
      if (formMode == "read") {
        let drugVal = values["Drug_ID"];
        let drugListInfo: any[] = userContext.drugSpecificDataList;
        let filteredDrug = drugListInfo.filter(
          (x: any) => x["DrugData_ID"] === Number(drugVal)
        );
        if (
          filteredDrug != null &&
          filteredDrug.length > 0 &&
          filteredDrug[0]["ActiveSubstanceNames"] != null
        ) {
          setNonVersionCol1(filteredDrug[0]["ActiveSubstanceNames"]);
        }
      } else {
        setNonVersionCol1(value);
      }
    }
  };

  const UpdateNonVersionCol2 = (value: string) => {
    if (dataSourceID == DataSourceListTypes.ReimbursementStatus) {
      if (formMode == "read") {
        let drugVal = values["Drug_ID"];
        let drugListInfo: any[] = userContext.drugSpecificDataList;
        let filteredDrug = drugListInfo.filter(
          (x: any) => x["DrugData_ID"] === Number(drugVal)
        );
        if (
          filteredDrug != null &&
          filteredDrug.length > 0 &&
          filteredDrug[0]["GlobalCorporationName"] != null
        ) {
          setNonVersionCol2(filteredDrug[0]["GlobalCorporationName"]);
        }
      } else {
        setNonVersionCol2(value);
      }
    }
  };



  const validateChildSection = (childSection: Section[]): boolean => {
    for (let i = 0; i < childSection.length; i++) {
      const currSection = childSection[i];
      let c_rows = newRows[currSection.id].filter(
        (x1) => x1.masterFieldId == null
      );
      let childCheck = c_rows.some((x) =>
        validateCriteria(x)
      );
      if (childCheck) return childCheck;
    }

    // childSection.forEach((cs) => {
    //   let c_rows = rows.filter(
    //     (x1) => x1.sectionId == cs.id && x1.masterFieldId == null
    //   );
    //   let childCheck = c_rows.some((x) => validateCriteria(x));
    //   if(childCheck) return childCheck;
    // })
    return false;
  };

  const validateSection = (section: Section, sideNav : boolean): boolean => {
    if(dataSourceID > 250 && dataSourceID != 274) return true
    let dataCount = 0;
    let _rowsSubpage = newRows[section.id]?.filter(
      (x1) => x1.masterFieldId != null
    ) || [];
    let _rows = newRows[section.id]?.filter(
      (x1) => x1.masterFieldId == null
    ) || [];
    if (!sideNav && dataSourceID == 31 && section.name == "Record info" && formMode != "read"){
      return false
    }
    if (formMode == "read" && section.name != "Disease") {
      _rows.map((y) => {
        if (
          !(
            values[y.name] == null ||
            values[y.name] == "" ||
            values[y.name] == undefined
          )
        ) {
          dataCount++;
        } else if (selectedLookupGlobe.hasOwnProperty(y.name)) {
          dataCount++;
        }
        if (dataSourceID == 20 && (section.name.toLowerCase().indexOf('regional') >= 0 || section.name.toLowerCase().indexOf('patent') >= 0))
          dataCount++;
        if (_rowsSubpage.length > 0) {
          const formMsKeys = Object.keys(selectedLookupGlobe);
          formMsKeys.forEach((key) => {
            if (key.indexOf(y.name) >= 0)
              dataCount++;
          })
        }
      });
      if(showEmptyFieldsFlag){
        const check = _rows.some((x) => validateCriteria(x));
        return check;
      }
      if (dataCount == 0) return false;
    }
    if (
      (formMode == "new" || formMode == "write") &&
      section.name != "Disease"
    ) {
      const childSection = sections.filter(
        (s) => s.parent_section_id == section.id
      );
      const check = _rows.some((x) => validateCriteria(x));
      let childCheck = false;
      if (childSection.length > 0) {
        childCheck = validateChildSection(childSection);
      }
      if (childCheck) return childCheck;
      return check;
    }
    return true;
  };

  const validateSubscriptionFields = (field: Field): boolean => {
    if( SubscriptionFieldCriteria[field.name]){
      const criteria : string[] = SubscriptionFieldCriteria[field.name];
      const values = selectedLookupGlobe.DataSourceIds?.map((x)=>x.Value) || [];
      return criteria.some((x) => values.some((y) => y == x));
    }    
    return true
  }

  const validateSubgroupRelatedFields = (sectionName: string, fieldName: string) => {
    if (sectionName == "Final recommendation" && 
      ((values["Country_ID"] == 9 && values["Agency_ID"] == 45) || values["Country_ID"] == 10) &&
      (values["IsSubgroupEnabled"] == 1) &&
        ["ReimbursementRateFrance_ID",
          "ASMRFrance_ID", 
          "SMRFrance_ID", 
          "BenefitRatingGermany_ID"].includes(fieldName)) {
            return false;
          } else {
            return true;
          }
  }

  const validateCriteria = (field: Field): boolean => {
       
    if(field.name == "HTA_Extrapolation"){
      return true;
    }else if(field.name == "FilterFieldLabel" || field.name == "FilterFieldSection"){
      if(values.IsFilterFieldEnabled)
        return true
      else
        return false
    }else if(field.name == "ResultFieldLabel"){
      if(values.IsResultFieldEnabled)
        return true
      else  
        return false
    }

    if(dataSourceID == 274) {
      return validateSubscriptionFields(field)
    }
    if(dataSourceID >270 || dataSourceID == 267) return true
    if (field.name == "ReimbursementStatus_WebLink") {
      if (values["WebLink"]?.length > 0) return true;
    }

    if (
      field.name == "Assign" ||
      field.name == "AssignQreviewer" ||
      field.name === "Direct_Link" ||
      field.name === "Cloned_From" ||
      (field.name === "Region_ID" && dataSourceID == DataSourceListTypes.Agreement)
    ) {
      return true;
    }

    //validate field criteria for view mode
    if (
      formMode == "read" &&
      !showEmptyFieldsFlag &&
      !(
        field.fieldTypeId == FormFieldType.MultiSelect ||
        field.fieldTypeId == FormFieldType.DrugSelect ||
        field.fieldTypeId == FormFieldType.DeviceSelect ||
        field.fieldTypeId == FormFieldType.ClinicalDataSelect
      )
    ) {
      if(field.name == "ArmsCount" && values.ArmsCohorts?.length > 0){
        return true
      }
      if (values[field.name] == null || values[field.name] == "") {
        return false;
      }
    }

    if (
      formMode == "read" &&
      !showEmptyFieldsFlag &&
      field.fieldTypeId == FormFieldType.MultiSelect &&
      field.lookupId == LookupIds.HTA_Drug_Combination
    ) {
      let currentMultiSelectValueSpecial = selectedLookupGlobeSpecial.hasOwnProperty(
        field.name
      );
      if (
        currentMultiSelectValueSpecial == null ||
        currentMultiSelectValueSpecial == undefined ||
        currentMultiSelectValueSpecial == false
      ) {
        return false;
      }
    }

    if (
      formMode == "read" &&
      !showEmptyFieldsFlag &&
      (field.fieldTypeId == FormFieldType.MultiSelect ||
        field.fieldTypeId == FormFieldType.DrugSelect ||
        field.fieldTypeId == FormFieldType.DeviceSelect ||
        field.fieldTypeId == FormFieldType.ClinicalDataSelect) &&
        !(field.lookupId == LookupIds.HTA_Drug_Combination ||
          field.lookupId == LookupIds.SourceOfComparator)
    ) {
      let currentMultiSelectValue = selectedLookupGlobe.hasOwnProperty(
        field.name
      );
      if (
        currentMultiSelectValue == null ||
        currentMultiSelectValue == undefined ||
        currentMultiSelectValue == false
      ) {
        return false;
      }
    }

    if (
      field.lookupId == LookupIds.SourceOfComparator &&
      selectedLookupGlobe.HTARecord_DrugComparator == undefined
      // ||
      // (field.lookupId == LookupIds.HTA_Drug_Combination &&
      //   selectedLookupGlobe.HTARecord_DrugComparator == undefined)
    ) {
      return false;
    }

    // view mode field criteria ends here ....

    // form mode field criteria starts here ...
    if (field.name == "HTARecord_Drug_Combination")
      return selectedLookupGlobe?.HTARecord_Drug?.length > 0;
    if (field.name == "HTARecord_DrugComparator_Combination")
      return selectedLookupGlobe?.HTARecord_DrugComparator?.length > 0;
    if (field.name == "HTARecord_Sourceofcomparator")
      return selectedLookupGlobe?.HTARecord_DrugComparator?.length > 0;
    if (field.name == "ClinicalData_Drug_Combination")
      return selectedLookupGlobe?.ClinicalData_Drug?.length > 0;
    if (field.name == "ClinicalData_DrugControl_Combination")
      return selectedLookupGlobe?.ClinicalData_DrugControl?.length > 0;

    let _fieldCriterias = fieldCriterias.filter((x) => x.fieldId == field.id);

    if (!fieldCriterias.some((fc) => fc.fieldId == field.id)) return true;

    let maxSets = Math.max(
      ..._fieldCriterias.map((x) => {
        return x.combinationSets;
      })
    );

    if(fieldCriterias.length > 0){
      const Grouped_fieldCriterias: any = {};

  
  
      const checkFunc = (fcFields: any) => {
        // for data version field check
        // if (fcFields.fieldCriteriaFieldId == 831) return true; //Commented as it was not checking for DataVersion criteria.
        
        //
        let parentField: any = rows.find(
          (x) => x.id == fcFields.fieldCriteriaFieldId
        );
        if (parentField != undefined) {
          let parentValue = values[parentField["name"]];
          let parentMultiSelectValue = selectedLookupGlobe.hasOwnProperty(
            parentField["name"]
          );
          let parentCheckValue: any = fcFields.value;
          // if (
          //   fcFields.fieldCriteriaTypeId == 3 &&
          //   parentValue == true &&
          //   (parentCheckValue == 1 || parentCheckValue == "on")) {
          //   parentValue = 1;
          //   parentCheckValue = 1;
          // }
          if (
            fcFields.fieldCriteriaTypeId == 3 &&
            parentValue == true &&
            (parentCheckValue == "on" || parentCheckValue == 1)
          )
            return true;
          // if (
          //   fcFields.fieldCriteriaTypeId == 3 &&
          //   parentValue == 1 &&
          //   parentCheckValue == 1
          // )
          //   return true;
          if(parentField.fieldTypeId == 15 && parentValue != undefined && parentCheckValue){
            let newvalue = parentCheckValue.split("/");
            let _parentCheckValue = new Date(`${newvalue[1]}-${newvalue[0]}-${newvalue[2]}`).getDate();
            let _parentValue = new Date(parentValue).getDate();
            if(_parentCheckValue === _parentValue)
              return true;
            else
              return false;
          }
          else if (parentValue != undefined && parentCheckValue != parentValue)
            return false;
          if (parentMultiSelectValue) {
            const msCheck = selectedLookupGlobe[parentField["name"]].some(
              (y) => y.Id == fcFields.value
            );
            return msCheck;
          }
          if (parentCheckValue != parentValue) return false;
          return true;
        }
      };
  
      _fieldCriterias.forEach((el) => {
        if(Grouped_fieldCriterias[el.combinationSets]) 
            Grouped_fieldCriterias[el.combinationSets].push(el)
        else   
            Grouped_fieldCriterias[el["combinationSets"]] = [el]
    });
      const keys = Object.keys(Grouped_fieldCriterias)
      
      keys.forEach((key) => {
          const values = Grouped_fieldCriterias[key]
          const groupByField : any = {}
          values.forEach((el:any) => {
              if(groupByField[el.fieldCriteriaFieldId])
                  groupByField[el.fieldCriteriaFieldId].push(el)
              else
                  groupByField[el.fieldCriteriaFieldId] = [el]
          })
      
          Grouped_fieldCriterias[key] = groupByField
      })
      
      const _keys : string[] =  Object.keys(Grouped_fieldCriterias)
      
      const check1 = _keys.some((key) => {
          const combinationSet = Grouped_fieldCriterias[key]
          const c_keys = Object.keys(combinationSet)
          const check2 = c_keys.every((ck) => {
              const fieldSet = combinationSet[ck]
              // const f_keys = Object.keys(fieldSet);
              const check3 = fieldSet.some((fk) => {
                  const ffield = fieldSet[fk]
                  return checkFunc(fk);
              })
              return check3
          })
          return check2
      })

      return check1

    }


  

    

    // const func = (data: any[]) => {
    //   let check;
    //   if (maxSets > 1)
    //     check = data.every((x) => {
    //       const val = checkFunc(x);
    //       return val;
    //     });
    //   else
    //     check = data.some((x) => {
    //       const val = checkFunc(x);
    //       return val;
    //     });
    //   return check;
    // };

    // let value = false;
    // for (let i = 1; i <= maxSets; i++) {
    //   const keys = Object.keys(Grouped_fieldCriterias);
    //   if (maxSets > 1)
    //     value = keys.some((x) => {
    //       const val = func(Grouped_fieldCriterias[x]);
    //       return val;
    //     });
    //   else value = keys.every((x) => func(Grouped_fieldCriterias[x]));
    //   if (value) return value;
    // }
    // if (maxSets) return value;

    // for (let i = 1; i <= maxSets; i++) {
    //   let setValid = false;
    //   for (let fc of _fieldCriterias.filter((x) => x.combinationSets == i)) {
    //     let parentField : any = rows.find((x) => x.id == fc.fieldCriteriaFieldId);

    //     let parentValue = values[parentField["name"]];
    //     let parentCheckValue: any = fc.value;

    //     if (fc.fieldCriteriaTypeId == 3) {
    //       if (fc.value == "1") parentCheckValue = true;
    //       else parentCheckValue = false;

    //       if (parentValue == null) {
    //         parentValue = false;
    //       }
    //     }

    //     if (parentValue == parentCheckValue) {
    //       setValid = true;
    //       break;
    //     }
    //   }

    //   if (!setValid) return false;
    // }

    return true;
    //form mode field criteria ends here ...
  };

  const scrollToComment = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (myRef && myRef.current) myRef.current.scrollIntoView();
  };

  const chooseView = (value: string) => {
    if (value === "Record" && isMenuHidden) setIsMenuHidden(false);
    setCurrentView(value);
  };

  const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      setLoadingFile(true);
      const formData = new FormData();
      let tableName = getAttachmentInfo(dataSourceID);

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        formData.append("files", file);
      }
      const data = formData;
      let attachmentId = dataSourceID == 1 ? record_ID : values.ID
      axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/createAttachment/${attachmentId}/${tableName}`,data,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response && response.status && response.status === 201) {
          fetchAttachmentsFile();
        }
      });
    }
  };

  const fetchAttachmentsFile = async () => {
    setLoadingFile(true);
    let tableName = getAttachmentInfo(dataSourceID);
    let columnName = getIdFieldName(dataSourceID);
    let response;
    if (tableName != "N/A" && columnName != "N/A" && record_ID)
      response = await apiCall(
        `/api/fetchAttachments/${tableName}/${columnName}/${record_ID}/${currRoleId}`
      );

    if (!!response && response.data.id !== 0) {
      setAttachment_DB_ID(response.data.id);
    }

    if (!!response && !!response.data.data && response.data.data.length > 0) {
      let date = null;
      let tmp: any = [];
      for (let x of response.data.data) {
        if (!!x.UploadDate) {
          let splitDate = x.UploadDate.split("T");
          let ddmmyyy = splitDate[0].split("-");
          let dd = ddmmyyy[2];
          let mm = ddmmyyy[1];
          let yyyy = ddmmyyy[0];
          let hourSplit = splitDate[1].split(":");
          let hhmm = `${hourSplit[0]}:${hourSplit[1]}`;
          date = `${dd}/${mm}/${yyyy} ${hhmm}`;
        }
        let obj = {
          fileName: x.FileName,
          fileData: x.FileData,
          id: x.ID,
          date,
        };
        tmp.push(obj);
      }
      setAttachmentList(tmp);
    }
    else {
      setAttachmentList({});
    }
    setLoadingFile(false);
  };

  useEffect(() => {
      fetchAttachmentsFile();
  }, []);

  useEffect(() => {
    if (
      dataSourceID == DataSourceListTypes.ReimbursementStatus &&
      values["Drug_ID"] != null &&
      formMode == "read"
    ) {
      UpdateNonVersionCol1("");
      UpdateNonVersionCol2("");
    }
  }, []);

  useEffect(() => {
    if (
      !isNaN(record_ID) &&
      typeof record_ID === "number" &&
      record_ID > 0 &&
      typeof dataSourceID === "number" &&
      !isEClient
    ) {
      dispatch(setIsSearchGridLoading(true));
      apiCall(`/api/auditLogForDs/${dataSourceID}/${record_ID}`).then(
        (response) => {
          let auditData = response.data;
          dispatch(setAuditLogData({auditData, record_ID, dataSourceID}));
          dispatch(setColumnFilters({ rowData: auditData, from: "AuditLog" }));
        }
      );
    }
  }, []);

  const downLoadAttachment = async(file: any, fileName: string,attachmentId: number) => {
    if (!!file) {
      let binaryString : any
        let bytes : any;
      try {
        binaryString = atob(file);
        bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          bytes[ i ] = binaryString.charCodeAt(i);
        }
        bytes = bytes.buffer
      } catch (error) {
        const tableName = getAttachmentInfo(dataSourceID);
        const columnName = getIdFieldName(dataSourceID);
        const res: any = await apiCall(`/api/fetchAttachment/${tableName}/${columnName}/${attachmentId}`);
        binaryString = res.data;
        bytes = new Uint8Array(res.data[ 0 ].FileData.data).buffer;
      }

      const blob = new Blob([bytes]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("style", "display: none");
      a.setAttribute("download", fileName);
      a.click();
      window.URL.revokeObjectURL(url);
      PostCall(`/api/insert/insertUserDownloadDetails`, {
        datasource_id: dataSourceID,
        datasource_name: datasourceDbName,
        download_type: "Record Attachment",
        pg_name: "Record attachment page" + "^" + window.location.href + "^",
        file_name: fileName
      }).then((resp) => {
        ToastrAlert("Record downloaded successfully", "success");
      });
    }
  };

  const deleteAttachment = async () => {
    if (deleteID) {
      setLoadingFile(true);
      let tableName = getAttachmentInfo(dataSourceID);
      let response = await DeleteCall(
        `/api/deleteAttachment/${tableName}/${deleteID}`
      );
      if (response && response.data === "Success") {
        fetchAttachmentsFile();
      } else {
        setLoadingFile(false);
      }
    }
  };

  const handleClickDelete = (id) => {
    setDeleteID(id);
    setShow(true);
  };
  const handleDeleteAttachment = () => {
    deleteAttachment();
    fetchAttachmentsFile();
    setShow(false);
  };

  const diseaseTable = () => {
    return (
      <div className="diseaseTable">
        <div className="tableOne">
          <div style={{ display: "flex", width: "102%"}}>
            {displayTable(1)}
          </div>
        </div>
        {showSecondDiseaseHierarchy && (
          <>
            <div className="tableSeparator"></div>
            <div style={{ display: "flex", width: "102%" }}>
              {displayTable(2)}
            </div>
          </>
        )}
      </div>
    );
  };
  
  const displayTable = (tableNumber: number) => {
    const table = tableNumber === 1 ? diseaseTableOne : diseaseTableTwo;
    return !!table.TherapeuticArea && table.TherapeuticArea.length > 1
      ? Object.entries(table).map((value: any, key: any) => (
          <div
            key={key}
            style={{ width: value[0] ==="ICD_Code" ? "7%" : value[0] !== "Invisible" ? "18.5%" : "2%" }}
          >
            {value[1].map((x: any, key2: any) => (
              <div
                key={"data _" + key2}
                style={
                  value[0] !== "Invisible"
                    ? x.level !== 0
                      ? {
                          height: x.rowSpan * 65,
                          borderBottom: "1px solid #ddd",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "-8px",
                          marginRight:"1em"
                        }
                      : {
                          backgroundImage:
                            "linear-gradient(to bottom,#f8f8f8 0%,#ececec 100%)",
                          backgroundRepeat: "repeat-x",
                          textAlign: "left",
                          lineHeight: "25px",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                          display:"flex",

                          //backgroundColor: "#f4f4f4",
                          //color: "black",
                          //borderTop: "1px solid red",
                          //borderBottom: "1px solid #ddd",
                          fontWeight: 700,
                          height: tableNumber === 1 ? 37 : 70,
                          marginLeft: "-9px",
                          //padding: "9px 0px",
                        }
                    : {
                        width: 20,
                      }
                }
              >
                <span
                  style={{
                    padding: "0px 0px",
                    display: x.level === 0 ? "inline-block":"contents",
                    fontSize: 12,
                    width:"120px",
                    wordWrap:"break-word",
                    whiteSpace:"normal",
                    lineHeight:"20px",
                  }}
                >
                  {x.level === 0
                    ? replaceOncology(
                        table.TherapeuticArea[1].name,
                        value[0],
                        x.name,
                        tableNumber
                      )
                    : x.name}
                </span>
              </div>
            ))}
          </div>
        ))
      : " ";
  };

  const replaceOncology = (
    firstTherapeuticArea: string,
    replaceLabel: string,
    label: string,
    tableNumber: number
  ) => {
    if (firstTherapeuticArea === "Oncology") {
      let obj: any = {
        TherapeuticArea: "Therapeutic area",
        Disease: "Disease",
        SubType1: "Cell Type / Location",
        SubType2: "Histology",
        SubType3: "Biomarker",
        SubType4: "Stage",
        ICD_Code: "ICD code",
      };
      return obj[replaceLabel];
    } else {
      if (tableNumber === 1) return label;
      else {
        let obj: any = {
          TherapeuticArea: "Secondary therapeutic area(s)",
          Disease: "Secondary disease",
          SubType1: "Secondary disease subtype 1",
          SubType2: "Secondary disease subtype 2",
          SubType3: "Secondary disease subtype 3",
          SubType4: "Secondary disease subtype 4",
          ICD_Code: "Secondary icd code",
        };
        return obj[replaceLabel];
      }
    }
  };

  const initializeNewObj = () => ({
    TherapeuticArea: createCategory("Therapeutic area"),
    Disease: createCategory("Disease"),
    SubType1: createCategory("Disease subtype 1"),
    SubType2: createCategory("Disease subtype 2"),
    SubType3: createCategory("Disease subtype 3"),
    SubType4: createCategory("Disease subtype 4"),
    Invisible: createCategory(""),
    ICD_Code: createCategory("ICD code"),
  });

  const createCategory = (name: string) => [
    {
      name,
      rowSpan: 1,
      title: true,
      level: 0,
    },
  ];

  const updateCategory = (
    category: any,
    cell: any,
    index: number,
    isICD: boolean
  ) => {
    let found = false;
    for (let x of category) {
      if (x.name === cell.Name && x.name !== null && category !== "ICD_Code") {
        x.rowSpan++;
        found = true;
        break;
      }
    }
    if (!found) {
      category.push({
        name: isICD ? cell.ICD : cell.Name ? cell.Name : " ",
        rowSpan: 1,
        title: false,
        level: index,
      });
    }
  };

  const formateReimbursementDiseaseHierachyData = (value: any) => {
    console.log(value, "value");
    let newObj = initializeNewObj();
    let rowIndex = 0;

    value.map((row: any, index: number) => {
      let icdInfo;
      rowIndex = index + 1;
      let emptyCell = 6;

      row
        .sort((a: any, b: any) => a.Level - b.Level)
        .forEach((cell: any) => {
          if (cell.Level === 0) {
            icdInfo = cell;
            updateCategory(newObj["TherapeuticArea"], cell, rowIndex, false);
            emptyCell--;
          }
          if (cell.Level === 1) {
            icdInfo = cell;
            updateCategory(newObj["Disease"], cell, rowIndex, false);
            emptyCell--;
          }
          if (cell.Level === 2) {
            icdInfo = cell;
            updateCategory(newObj["SubType1"], cell, rowIndex, false);
            emptyCell--;
          }
          if (cell.Level === 3) {
            icdInfo = cell;
            updateCategory(newObj["SubType2"], cell, rowIndex, false);
            emptyCell--;
          }
          if (cell.Level === 4) {
            icdInfo = cell;
            updateCategory(newObj["SubType3"], cell, rowIndex, false);
            emptyCell--;
          }
          if (cell.Level === 5) {
            icdInfo = cell;
            updateCategory(newObj["SubType4"], cell, rowIndex, false);
            emptyCell--;
          }
        });
      if (emptyCell === 1) {
        addEmptyCell(newObj["SubType4"], rowIndex);
      } else if (emptyCell === 2) {
        addEmptyCell(newObj["SubType3"], rowIndex);
        addEmptyCell(newObj["SubType4"], rowIndex);
      } else if (emptyCell === 3) {
        addEmptyCell(newObj["SubType2"], rowIndex);
        addEmptyCell(newObj["SubType3"], rowIndex);
        addEmptyCell(newObj["SubType4"], rowIndex);
      } else if (emptyCell === 4) {
        addEmptyCell(newObj["SubType1"], rowIndex);
        addEmptyCell(newObj["SubType2"], rowIndex);
        addEmptyCell(newObj["SubType3"], rowIndex);
        addEmptyCell(newObj["SubType4"], rowIndex);
      } else if (emptyCell === 5) {
        addEmptyCell(newObj["Disease"], rowIndex);
        addEmptyCell(newObj["SubType1"], rowIndex);
        addEmptyCell(newObj["SubType2"], rowIndex);
        addEmptyCell(newObj["SubType3"], rowIndex);
        addEmptyCell(newObj["SubType4"], rowIndex);
      }
      updateCategory(newObj["ICD_Code"], icdInfo, rowIndex, true);
    });
    setDiseaseTableOne(newObj);
  };

  const addEmptyCell = (category: any, rowIndex: any) => {
    console.log("roxIndex", rowIndex);
    category.push({
      name: " ",
      rowSpan: 1,
      title: false,
      level: rowIndex,
    });
  };

  const formateDiseaseHierachyData = (value: any, tableNum: number) => {
    // console.log("formateDiseaseHierachyData", value);
    let newObj: any = {
      TherapeuticArea: [
        {
          name: "Therapeutic area",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
      Disease: [
        {
          name: "Disease",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
      SubType1: [
        {
          name: "Disease subtype 1",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
      SubType2: [
        {
          name: "Disease subtype 2",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
      SubType3: [
        {
          name: "Disease subtype 3",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
      SubType4: [
        {
          name: "Disease subtype 4",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
      Invisible: [
        {
          name: "",
          rowSpan: 1,
          title: true,
          level: 0,
        },
      ],
      ICD_Code: [
        {
          name: "ICD code",
          rowSpan: 1,
          title: true,
          level: 0,
          id: 0,
        },
      ],
    };

    if (!!value[0] && value[0].length > 0) {
      value[0].forEach((item: any, index: number) => {
        // console.log(item, "item", index);
        for (const key in item) {
          // console.log(key, "key");
          if (newObj[key]) {
            let found = false;
            for (let x of newObj[key]) {
              if (
                x.id === item[key + "_ID"] &&
                x.id !== null &&
                key !== "ICD_Code"
              ) {
                x.rowSpan++;
                found = true;
                break;
              }
            }
            if (!found) {
              newObj[key].push({
                name: item[key] ? item[key] : " ",
                rowSpan: 1,
                title: false,
                level: index + 1,
                id: item[key + "_ID"] ? item[key + "_ID"] : null,
              });
            }
          }
        }
      });
    }

    // console.log(newObj, "newObj");
    tableNum === 1 ? setDiseaseTableOne(newObj) : setDiseaseTableTwo(newObj);
  };

  const fetchDiseaseHierachyData = async () => {
    if(record_ID && dataSourceID)
      apiCall(`/api/getDiseaseInfo/${record_ID}/${dataSourceID}/${versionID}`).then(
      (response) => {
        if (formMode === "read") {
          if (
            response &&
            response.data &&
            Object.entries(response.data).length > 0
          ) {
            if (dataSourceID !== 25)
              formateDiseaseHierachyData(response.data.tableOne, 1);
            else formateReimbursementDiseaseHierachyData(response.data);

            if (
              !!response.data.tableTwo &&
              response.data.tableTwo[0] &&
              response.data.tableTwo[0].length > 0 &&
              dataSourceID === 1
            ) {
              setShowSecondDiseaseHierarchy(true);
              formateDiseaseHierachyData(response.data.tableTwo, 2);
            }
          }
        }
      }
    );
  };

  useEffect(() => {
    //1,2,3,23,25 required for these datasources
    if([1,2,3,23,25].includes(dataSourceID)){
      fetchDiseaseHierachyData();      
    }
    if(dataSourceID < 250)
      fetchWorkflowReasonData();
  }, []);

  const hideMenu = (event: any) => {
    event.preventDefault();
    setIsMenuHidden(true);
  };

  const formButtonEditRecord = (event: any) => {
    event.preventDefault();
    // window.location.href = window.location.href //Commented as this was allowing to edit the record without any check.
    //   .replace("View", "Form")
    //   .replace("view", "form");
    if (
      (String(values["Status_ID"]) == String(Status.R)) &&
      values["QAssociate"] == accountid &&
      values["QReviewer"] != accountid &&
      dataSourceID == DataSourceListTypes.HTARecord
    ) {
      setEditOnProgress(true);
      let _values = {...values};
      _values = {..._values, ['Status_ID']: Status.WIPR};
      setNextStatusId(Status.WIPR);
      createNewVersion(
        dataSourceID,
        values[col_items[dataSourceID]],
        0,
        0,
        _values,
        true
      );
      return;
    }
    if (values["Status_ID"] == Status.WIPR && values["QAssociate"] == null) {
      ToastrAlert("No associate is assigned for this record. Please assign user to continue editing", "warning");
      return false;
    }
    if (
      (String(values["Status_ID"]) == String(Status.SFR) ||
        String(values["Status_ID"]) == String(Status.SFRR)) &&
      values["QAssociate"] == accountid &&
      values["QReviewer"] == null
    ) {
      ToastrAlert("No reviewer is assigned for this record. Please assign user to continue editing", "warning");
      return false;
    }
    if (
      (String(values["Status_ID"]) == String(Status.SFR) ||
        String(values["Status_ID"]) == String(Status.SFRR)) &&
      values["QAssociate"] == accountid &&
      values["QReviewer"] != accountid
    ) {
      ToastrAlert("This record is only editable by reviewer of the record", "warning");
      return false;
    }
    if (
      (String(values["Status_ID"]) == String(Status.SFR) ||
        String(values["Status_ID"]) == String(Status.SFRR)) &&
      values["QReviewer"] == null
    ) {
      return false;
    }
    if (values["Status_ID"] >= Status.RC && values["QAssociate"] == null && values["QReviewer"] == null) {
      ToastrAlert("No associate/reviewer is assigned for this record. Please assign user to continue editing", "warning");
      return false;
    }
    if (
      (String(values["Status_ID"]) == String(Status.WIP) ||
        String(values["Status_ID"]) == String(Status.WIPR) ||
        String(values["Status_ID"]) == String(Status.A) ||
        String(values["Status_ID"]) == String(Status.AR)) &&
      values["QAssociate"] != accountid
    ) {
      ToastrAlert("This record is only editable by associate of the record", "warning");
      return false;
    }
    if (
      (String(values["Status_ID"]) == String(Status.SFR) ||
        String(values["Status_ID"]) == String(Status.SFRR)) &&
      !(values["QReviewer"] == accountid)
    ) {
      ToastrAlert("This record is only editable by reviewer of the record", "warning");
      return false;
    }
    if (
      String(values["Status_ID"]) == String(Status.R) &&
      values["QAssociate"] == accountid &&
      values["QReviewer"] != accountid
    ) {
      ToastrAlert("This record is only editable by reviewer of the record", "warning");
      return false;
    }
    if (
      String(values["Status_ID"]) == String(Status.R) &&
      values["QReviewer"] != accountid
    ) {
      ToastrAlert("This record is only editable by reviewer of the record", "warning");
      return false;
    }
    let _pathname : string = navigate.location.pathname;
    navigate.push(_pathname.replace("View", "Form").replace("view", "form"));
    // window.location.href = window.location.href
    //   .replace("View", "Form")
    //   .replace("view", "form");
  };

  const formButtonViewRecord = (event: any) => {
    event.preventDefault();
    let _pathname : string = navigate.location.pathname;
    navigate.push(_pathname.replace("Form", "View").replace("form", "view"));
    // window.location.href = window.location.href
    //   .replace("Form", "View")
    //   .replace("form", "view");
  };

  const SecurityMap = (btnType: string): boolean => {
    if (
      btnType == "ShowSRRButton" &&
      (values["Status_ID"] == 4 || values["Status_ID"] == 5)
    )
      return true;
    if (
      btnType == "ShowARRButton" &&
      (values["Status_ID"] == 6 || values["Status_ID"] == 7)
    )
      return true;
    if (
      btnType == "ShowMRRButton" &&
      (values["Status_ID"] == 5 || values["Status_ID"] == 7)
    )
      return true;
    if (
      btnType == "ShowRWIPButton" &&
      (values["Status_ID"] == 6 ||
        values["Status_ID"] == 7 ||
        values["Status_ID"] == 8)
    )
      return true;

    return false;
  };

  const GetNextStatus = (currentStatusId: string, buttonName: string) => {
    if (buttonName === "R") return "8";
    else {
      switch (currentStatusId) {
        case "2":
          return "4";
        case "3":
          return "5";
        case "4":
          return "6";
        case "5":
          return "7";
        case "6":
          return "4";
        case "7":
          return "5";
        case "8":
          return "5";
        default:
          return "1";
      }
    }
  };

  const comparePricingRecord = async () => {
    // 27166
    if (compareWithValue !== "") {
      let id = Number(compareWithValue);
      let response = await apiCall(`/api/getPricingChartInfo/${id}`);
      // console.log("response", response);
      if (
        response &&
        response.data &&
        Object.entries(response.data).length > 0 &&
        response.data.recordData.length > 0
      ) {
        setPricingCompareRecord(response.data.recordData[0]);
      }
    }
  };

  const handleCommentModal = (view: string) => {
    setShowModal(true);
    setCommentModalViewl(view);
  };

  const handleRecordDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorRecDel(event.currentTarget);
  };

  const handleAttachementDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAttachmentDel(event.currentTarget);
  };

  const handleClientNewsDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setClientNewsDel(event.currentTarget);
  };

  const handleDisableExportDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setDisableExportDel(event.currentTarget);
  }

  const handleRecordAssignment = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (values != null && values["QAssociate"] != null) {
      const associateObj: AdminObj = userList.find(
        (opt) => opt.ID == values["QAssociate"]
      );
      setSelectedAssociate(associateObj);
      // setSelectedAssociateName(associateObj.label);
    }
    if (values != null && values["QReviewer"] != null) {
      const reviewerObj: AdminObj = userList.find(
        (opt) => opt.ID == values["QReviewer"]
      );
      setSelectedReviewer(reviewerObj);
      // setSelectedReviewerName(reviewerObj.label );
    }
    if (values != null && values["Due_date_data"] != null) {
      setAssociateDueDate(values["Due_date_data"]);
    }
    if (values != null && values["Due_date_review"] != null) {
      setReviewerDueDate(values["Due_date_review"]);
    }
    if (values != null && values["QAssociate"] != null) {
      setEnteredComment(values["Comment"]);
    }
    // setAnchorAssign(event.currentTarget);
    setShowAssignModal(true);
  };

  const getRelevantReasons = (nextStatus: number) => {
    let res = workflowListAll.filter(
      (x) => x["Status_Ids"].indexOf(String(nextStatus)) >= 0
    );
    if (res && res.length > 0) {
      let arr = [];
      for (let c of res) {
        arr.push({
          label: c.Value,
          ID: c.Id,
        });
      }
      setWorkflowList(arr);
    }
  };

  const handleWorkflow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const nextStatus = Number(
      GetNextStatus(String(values["Status_ID"]), "WIP")
    );
    let valid = true;
    const [res, requiredFields] = ValidateFormForSFRandSFRR(
      dataSourceID,
      nextStatus,
      selectedLookupGlobe,
      values,
      setConditionalFormFields,
      valid,
      lookupSource
    );
    if (res) {
      // values["Status_ID"] = nextStatus;
      setNextStatusId(nextStatus);
      getRelevantReasons(nextStatus);
      setAnchorWorkflowChange(event.currentTarget);
    } else {
      ToastrAlert("One of the form fields is missing or invalid", "warning");
      setConditionalFormFields(requiredFields);
    }
  };

  const handleWorkflowApprove = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    nextStatus = Number(GetNextStatus(String(values["Status_ID"]), "R"));
    setNextStatusId(nextStatus);
    getRelevantReasons(nextStatus);
    setButtonName('R');
    setAnchorWorkflowChange(event.currentTarget);
  };

  const confirmWorkflowChange = () => {
    setFormSavingProgress(true);
    let logWorkHH: number = 0;
    let logWorkMM: number = 0;
    if (workLogMinute > 59) {
      setWorkLogMinute(0);
      setMinuteHourValidation("Minute value should be less then 60.");
      setFormSavingProgress(false);
      return false;
    }
    if (
      (workLogHour == 0 && workLogMinute == 0) ||
      workLogHour == null ||
      workLogMinute == null
    ) {
      setMinuteHourValidation(
        "Log work value should not be zero or blank. Please enter time to log work."
      );
      setFormSavingProgress(false);
      return false;
    }
    if (
      (workLogHour == null || workLogHour == undefined) &&
      (workLogMinute == null || workLogMinute == undefined)
    ) {
      setMinuteHourValidation(
        "Both value should not be zero or blank. Please enter time to log work."
      );
      setFormSavingProgress(false);
      return false;
    } else {
      setMinuteHourValidation("");
    }
    if (workLogHour > 0) {
      logWorkHH = Number(workLogHour);
    }
    if (workLogMinute > 0) {
      logWorkMM = Number(workLogMinute);
    }
    if (workLogHour == null) {
      logWorkHH = 0;
    }
    if (workLogMinute == null) {
      logWorkMM = 0;
    }
    let logWork = logWorkHH * 60 + Number(logWorkMM);
    let worklogAssociate = 0;
    let worklogReviewer = 0;
    if (
      String(values["Status_ID"]) == String(Status.WIP) ||
      String(values["Status_ID"]) == String(Status.WIPR)
    ) {
      worklogAssociate = logWork;
    } else if (
      String(values["Status_ID"]) == String(Status.SFR) ||
      String(values["Status_ID"]) == String(Status.SFRR)
    ) {
      worklogReviewer = logWork;
    }
    let _values = {...values};
    const nextStatus = Number(
      GetNextStatus(String(values["Status_ID"]), buttonName)
    );
    _values = {..._values, ['Status_ID']: nextStatus};
    // values["Status_ID"] = nextStatus;
    setNextStatusId(nextStatusId);
    setAnchorWorkflowChange(null);
    createNewVersion(
      dataSourceID,
      values[col_items[dataSourceID]],
      worklogAssociate,
      worklogReviewer,
      _values
    );
  };

  const validateHourLog = (event: any, hourOrMinute: string) => {
    const regx = /^[0-9\b]+$/;
    if (regx.test(event.target.value)) {
      if (hourOrMinute == "mnt") {
        setWorkLogMinute(Number(event.target.value));
      }
      if (hourOrMinute == "hrs") {
        setWorkLogHour(Number(event.target.value));
      }
    } else {
      return false;
    }
  };

  const fetchWorkflowReasonData = async () => {
    apiCall(`/api/admin/workFlowReason/`).then((resp) => {
      if (resp.data && resp.data.length > 0) {
        setWorkflowListAll(resp.data);
      }
    });
  };

  const handleCloseRecDel = () => {
    setAnchorRecDel(null);
    console.log("AnchorRecDel", anchorRecDel)
  };
  
  const handleCloseAttachmentDel = () => {
    setAttachmentDel(null);
  };

  const handleClientNewsDel = () => {
    setClientNewsDel(null);
  };

  const handleCloseDisableExportDel = () => {
    setDisableExportDel(null);
  };
  const handleCloseWorkflow = () => {
    setAnchorWorkflowChange(null);
  };
  const handleCloseAssign = () => {
    setAnchorAssign(null);
  };
  //For deletion part:
  const datasourceRecordDelete = (id:any) => {
    
    axios.put('http://localhost:3000/api/lookup/updatelookup/' +id).then(res=>{
      console.log("Record_Id",id)
      console.log("Data Uploaded")
      setAnchorRecDel(null);
      //navigate.push("/Search/ByDrugClinical");
      navigate.push(`/admin/list/${dataSourceID}`)

    })
  };

  //For Disease Display Name Deletion part:
  const diseaseNamesDelete = (id:any) => {
    axios.put('http://localhost:3000/api/lookup/updatediseasedisplayNames/' +id).then(res=>{
      console.log("Record_Id",id)
      console.log("Data Uploaded")
      setAnchorRecDel(null);
      //navigate.push("/Search/ByDrugClinical");
      navigate.push(`/admin/list/${dataSourceID}`)

    })
  };

  const displayDelete=()=>{
    diseaseNamesDelete(values.ID)
  }


  //In useCallbacks function we have so many function every popup delete will be work based on that. So need to have it in one method:
  const dataSourceDelete=() => {
    // let id = {values.id}
    // let id='http://localhost:3001/admin/form/253/:id'
    // datasourceRecordDelete(params.recordID)
    datasourceRecordDelete(values.ID)
  }

  //For Disease Deletion part:
  const diseaseDelete=(id:any)=>{
    axios.put('http://localhost:3000/api/lookup/updateddisease/' +id).then(res=>{
      console.log("Record_Id",id)
      console.log("Data Uploaded")
      setAnchorRecDel(null);
      navigate.push(`/admin/list/${dataSourceID}`)
    })
  }

  //For Deleting the disease part:
  const diseaseDeletionpart=()=>{
    diseaseDelete(values.ID)
  }

  const disableExportOptionsDelete = async() => {
    await DeleteCall(`/api/admin/delete/table/${dataSourceID}/${record_ID}`);
    navigate.push(`/admin/list/${dataSourceID}`);
  }

  //For Workflowreasons fetching part:
  // const[workflowReasons,setWorkflowReasons]=useState()
  //   useEffect(()=>{
  //   axios.get('http://localhost:3000/api/admin/workFlowReason').then(res=>{
  //     console.log("Data recieved")
  //     setWorkflowReasons(res.data)
  //     // console.log("Data recieved", workflowReasons)
  //   }).catch(err=>{
  //     console.log(err.message)
  //   })
  // },[])

//For switch statements:
  const deletionPart=()=>{
    let x=params.dataSourceID
    console.log("X values", x)
    if (x < 250) {
      deleteDatasourceForm(x, values[col_items[dataSourceID]]);
    }
    else {
      switch(x){
        case '253':
          dataSourceDelete();
          break;
        case '254':
          displayDelete();
          break;
        case '255':
          diseaseDeletionpart();
          break;
        case '265':
          disableExportOptionsDelete();
          break
        default:
          alert("Not its done");
      }
    }
  }
  // const displayDelete=()=>{
  //   diseaseNamesDelete(values.ID)
  // }

  
  // const {id}=useParams();

  // useEffect(()=>{
  //   axios.get('http://localhost:3000/api/lookup/updatelookup').then(res=>{
  //     console.log("Data recieved")
  //     setDevice(res.data)
  //     console.log("Data recieved", device)
  //   }).catch(err=>{
  //     console.log(err.message)
  //   })
  // },[])

  const recordAssignment = () => {
    let isStatusChange = "true";
    if (typeValue == "1") {
      if (selectedAssociate.ID == 0) {
        setAssignmentValidation("Please assign associate");
        return false;
      }
      if (selectedReviewer.ID == 0) {
        setAssignmentValidation("Please assign reviewer");
        return false;
      }
      if (selectedAssociate.ID == 0 && selectedReviewer.ID == 0) {
        setAssignmentValidation("Please assign associate and reviewer");
        return false;
      }
      if (selectedAssociate.ID != 0 && (!associateDueDate || associateDueDate == null || associateDueDate == '') ) {
        setAssignmentValidation("Please assign associate due date");
        return false;
      }
      if (selectedReviewer.ID != 0 && (!reviewerDueDate || reviewerDueDate == null || reviewerDueDate == '') ) {
        setAssignmentValidation("Please assign reviewer due date");
        return false;
      }
    }
    if (typeValue == "2" && selectedAssociate.ID == 0) {
      setAssignmentValidation("Please assign associate");
      return false;
    }
    if (typeValue == "2" && selectedAssociate.ID != 0 && (!associateDueDate || associateDueDate == null || associateDueDate == '') ) {
      setAssignmentValidation("Please assign associate due date");
      return false;
    }
    if (typeValue == "3" && selectedReviewer.ID == 0) {
      setAssignmentValidation("Please assign reviewer");
      return false;
    }
    if (typeValue == "3" && selectedReviewer.ID != 0 && (!reviewerDueDate || reviewerDueDate == null || reviewerDueDate == '') ) {
      setAssignmentValidation("Please assign reviewer due date");
      return false;
    }
    if (
      typeValue == "1" &&
      selectedAssociate.ID == values["QAssociate"] &&
      selectedReviewer.ID == values["QReviewer"] &&
      associateDueDate == values["Due_date_data"] &&
      reviewerDueDate == values["Due_date_review"]
    ) {
      setAssignmentValidation("Please modify the current criteria");
      return false;
    } else if (
      typeValue == "2" &&
      selectedAssociate.ID == values["QAssociate"] &&
      associateDueDate == values["Due_date_data"]
    ) {
      setAssignmentValidation("Please modify the current criteria");
      return false;
    } else if (typeValue == "3" && 
      selectedReviewer.ID == values["QReviewer"] &&
      reviewerDueDate == values["Due_date_review"]
    ) {
      setAssignmentValidation("Please modify the current criteria");
      return false;
    }
    if (selectedAssociate.ID == values["QAssociate"]) {
      isStatusChange = "false";
    }
    setFormSavingProgress(true);
    AssignUser(
      dataSourceID,
      values["ID"],
      values[col_items[dataSourceID]],
      values,
      selectedLookupGlobe,
      selectedLookupGlobeSpecial,
      selectedAssociate.ID,
      selectedReviewer.ID,
      associateDueDate != null ? associateDueDate : "",
      reviewerDueDate != null ? reviewerDueDate : "",
      enteredComment != null ? enteredComment : "",
      typeValue,
      isStatusChange,
      setFormSavingProgress,
      assignModalClose,
      rows,
    );
  };

  const navigate = useHistory();

  const drugSearch = async (event: any, element: string) => {
    event.preventDefault();
    let drug = await apiCall(`/api/getDrug/${values.Drug_ID}`);
    // console.log("drug", drug);
    let obj: any = {};
    let item: any = {};
    if (drug.data.length > 0) {
      item.Id = values.Drug_ID;
      item.Value = drug.data[0].Name;
      obj.DrugIDs = [item];
    }
    // console.log(obj, "obj", item, "item");
    dispatch(setSelectedDataState(item));
    if (element === "Clinical") {
      dispatch(setSelectedLookupGlobe(obj));
      navigate.push("/Search/ByDrugClinical");
      // window.open('/Search/ByDrugClinical', '_blank');
    } else if (element === "HTA") {
      let country = await apiCall(`/api/getCountry/${values.Country_ID}`);
      // console.log("country", country);
      if (country.data && country.data.length > 0) {
        obj.CountryIDs = [
          { Id: values.Country_ID, Value: country.data[0].Name },
        ];
        dispatch(setSelectedLookupGlobe(obj));
        navigate.push("/Search/ByDrug");
      }
    } else if (element === "Regulatory") {
      let regulators = await apiCall(`/api/getRegulator/${values.Country_ID}`);
      //console.log("regulators", regulators);
      if (regulators.data && regulators.data.length > 0) {
        (obj.RegulatorIDs = regulators.data),
          dispatch(setSelectedLookupGlobe(obj));
        navigate.push("/Search/ByDrugRegulatory");
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };


  const getFormModeName= () => {

    if (formMode == "write") {
      return `Edit ${getCustomDatasourceName(dataSourceID)}`;
    } else {
      if(dataSourceID == 254)
        return `New ${getCustomDatasourceName(dataSourceID)}`;
      if ([255,256,257,258,259,260,262,266,267, 253,274,276].includes(dataSourceID))
        return `Create ${getCustomDatasourceName(dataSourceID)}`;
      else if ([265,268, 269, 271, 272].includes(dataSourceID))
        return getCustomDatasourceName(dataSourceID);
      else
        return `Add ${getCustomDatasourceName(dataSourceID)}`;
     }
  }
      const deleteDatasourceForm = async (datasourceId: any, recordId: any) => {
    let response = await DeleteCall(
      `/api/delete/versionRecord/${datasourceId}/${recordId}`
    );
    if (response && response.data === "Success") {
      window.location.href = String(
        window.location.protocol +
          "//" +
          window.location.host
      );
    } else {
      ToastrAlert("Record deletion was not successful", "warning");

    }
  }

  return (
    <>
    {show == true && 
       <Modal size="sm" show={open} onHide={handleClose} centered>
         <Modal.Header>
           <Modal.Title>Delete confirmation</Modal.Title>
           <CloseIcon className="Close-icon" onClick={handleClose} />
         </Modal.Header>
       <Modal.Body>
           <div className="bodyTop">
                   <p>
                     Do you want to delete this file?
                   </p>
           </div>
         </Modal.Body>
         <Modal.Footer style={{ cursor: "pointer",color:"rgb(0, 170, 231)"  }}>
         <span className="spanBtn" onClick={handleDeleteAttachment}>
             Yes
           </span>
         <span>|</span>
           <span className="spanBtn" onClick={handleClose}>
             Cancel
           </span>
         </Modal.Footer>
       </Modal>
      }   
      {!isLoading && !isLoadingFC && (
        <>
          <div style={{ padding: "0em 2em 0em 4em" }}>
            <div className="main-container col-sm-12 row">
              <div className="subheading col-sm-12 text-center">
                <h3
                  className="page-header"
                  style={{
                    fontSize: "22px",
                    fontWeight: "normal",
                    marginBottom: "20px",
                  }}
                >
                  {title}
                  {(values['ModifiedDate'] && new Date(values['ModifiedDate']).toISOString().split('T')[0] >  new Date(Date.now()- 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]) &&
                    <PublishedWithChangesIcon className="recently-updated" titleAccess="Record updated in the last 30 days" />
                  }
                </h3>
                { formMode === "read" && !(currRoleId > 4 && currRoleId < 8) && (
                  <div>
                    <p>
                      <b
                         style={{  fontSize: "14px",display:"inline" }}
                      >
                        {summaryConclusionLabel}
                      </b>
                      {summaryOrConclusionText}
                    </p>

                  </div>
                )
                }
                 {
                  dataSourceID < 250 && <>
                <div className="separator"></div>
                <div className="menuRecord">
                  <span onClick={() => chooseView("Record")}>Record</span>
                  {dataSourceID !== 4 && (
                    <span onClick={() => chooseView("Attachments")}>
                      Attachments
                    </span>
                  )}
                  {formMode != "new" && !isEClient && (
                    <span onClick={() => chooseView("Audit")}>Audit log</span>
                  )}
                </div>
                  </>
                }

              </div>
              {currentView === "Record" && dataSourceID !== 4 && (
                <div
                  className="leftactionpane col-md-2 record-view-height"
                  hidden={isAdminPage}
                  style={
                    isMenuHidden
                      ? { padding: "0px", display: "none" }
                      : { padding: "0px", height: "670px" }
                  }
                >
                  <ul
                    className="ace-left-nav ui-sortable"
                    data-spy="affix"
                    data-offset-top="205"
                    style={{ padding: "0px" }}
                  >
                    <li className="ui-sortable-handle">
                      <div>
                        <a
                          className="pull-right"
                          href="#"
                          id="left-ace-nav-btn"
                          title="Hide this Menu"
                          onClick={(e) => {
                            e.preventDefault();
                            hideMenu(e)}}
                        >
                          <i
                            className="fa fa-angle-left"
                            aria-hidden="true"
                            style={{ fontSize: "x-large", paddingRight: "0px",color: "rgb(0,171,231)" }}
                          ></i>
                        </a>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                          height: "66px",
                        }}
                      >
                        <b>
                          <span
                            style={{ paddingTop: "20px", display: "block" }}
                          >
                            Record Layout
                          </span>
                        </b>
                      </div>
                    </li>
                    {Object.keys(newRows).length > 0 && sections
                      .filter(
                        (s) =>
                          (s.parent_section_id == null ||
                            s.parent_section_id == 0) &&
                          validateSection(s,true)
                      )
                      .map((el, index) => (
                        <li
                          className="hover-action ui-sortable-handle ace-left-nav-activated"
                          key={el.id}
                        >
                          <a
                            href={hashPrefix + "#section-" + el.id.toString()}
                            className="hover-action ui-sortable-handle ace-left-nav li.hover-action:hover"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingLeft: "5px",
                                color: "#00aae7",
                                 borderLeft: el.id == 48 ? "2px solid rgb(0,84,134)" : " 0px solid ",
                              }}
                            >
                              {el.name}
                            </div>
                          </a>
                        </li>
                      ))}
                    {commentList && commentList.length > 0 ? (
                      <li className="hover-action ui-sortable-handle ace-left-nav-activated">
                        <a
                          href="#section-comment"
                          onClick={(e) => scrollToComment(e)}
                          style={{textDecoration:"none"}}
                        >
                          <div
                            style={{
                              paddingTop: "10px",
                              paddingLeft: "5px",
                              color: "#00aae7",
                            }}
                          >
                            Comments
                          </div>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              )}
              <div
                className={
                  (isAdminPage ||
                  currentView !== "Record" ||
                  isMenuHidden ||
                  dataSourceID === 4
                    ? "col-md-12"
                    : "col-md-10") + " rightactionpane record-view-height"
                }
                style={{
                  height: currentView === "Attachments" ? "34em" : "",
                }}
              >
                <form
                  onKeyDown ={(e) =>  e.key === 'Enter' && e.stopPropagation()}
                  onInvalid={() => {
                    if (dataSourceID == 20) setMandoryFieldMissing(true);
                  }}
                  onSubmit={(e) => {
                    // return false;
                    e.preventDefault();
                    const buttonType = window?.event?.submitter?.name;
                    if (buttonType == "preview")
                      navigate.push({ pathname: `/admin/confirm/${dataSourceID}/${record_ID ? record_ID : ""}`, state: { values: values, selectedValues: selectedLookupGlobe } })
                    else if (buttonType == "createNew")
                      formSave(true);
                    else
                      formSave(false);
                    // return false;
                  }}
                >
                  <div id="div-fixed" style={{ paddingLeft: "0px" }}>
                    <div id="record-operations" className="form-buttons">
                      <div
                        className="pull-left"
                        style={{
                          margin: "0px 0px 0px 0px",
                          minWidth: "100%",
                          paddingTop: "15px",
                          paddingBottom: "10px",
                        }}
                      >
                        <b className="record-operations-label" style={{ fontSize: "small" }}>
                          {/* {isReports ? "Filters" : datasourceName + " details"} */}
                          {dataSourceID < 250 && <span style={{ marginTop: "2px", marginLeft: "12px" }}>{isReports ? "Filters" : dataSourceID == 31 ? "Settings" : "Details"}</span>}


                          {dataSourceID > 250 && 
                                  <h2>{getFormModeName()}</h2>}
                        </b>

                       
                        <div className="float-end" style={{marginTop:"-6.8px"}}>
                          {formMode == "read" && dataSourceID !== 4 && dataSourceID < 250 && (
                            <>
                              {" "}
                              {showEmptyFieldsFlag && (
                                <VisibilityIcon style={{ color: "#00aae7" }} />
                              )}
                              {!showEmptyFieldsFlag && (
                                <VisibilityOffIcon
                                  style={{ color: "#00aae7" }}
                                />
                              )}
                              <button
                                className="btn"
                                type="button"
                                style={{ ...sizeColor, ["paddingLeft"]: "0px",fontSize:"12px" }}
                                onClick={showHideEmptyFields}
                              >
                                Show/hide empty fields
                              </button>{" "}
                            </>
                          )}
                          {formMode != "read" && dataSourceID == 2 && (
                            <>
                            <FaRegRectangleList
                                style={{
                                  color: "#00aae7",
                                  margin: "4px 2px 5px 0px",
                                  fontSize: "22px"
                                }}
                              />&nbsp;
                              <button
                                className="btn"
                                style={{
                                  ...sizeColor,
                                  ["paddingLeft"]: "0px",
                                }}
                                type="button"
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                 setShowPrePopulatedFieldsModal(event.currentTarget);
                                }}
                              >
                                Prepopulate fields
                              </button>
                            </>
                          )}
                          {
                            formMode != "read" && dataSourceID == 3 && <>
                            <FaFirstAid style={{
                                color: "#00aae7",
                                fontSize: "20px", float: "left", marginTop: "5px", marginRight: "3px"
                              }} />
                              <a
                                className="no-underline"
                                href= "/form/31"
                                target="_blank"
                                style={{
                                  ...sizeColor,
                                  [ "paddingLeft" ]: "0px",
                                }}
                              // onClick={() =>formSave}>
                              >
                                {"Assumption"}
                              </a>
                            </>
                          }
                          {formMode != "read" && dataSourceID < 250 && (
                            <>
                              
                              <BiSave
                                style={{
                                  color: "#00aae7",
                                  margin: "4px 2px 5px 0px",
                                  fontSize: "22px",
                                  marginBottom: "9px"
                                }}
                              />&nbsp;
                              <button
                                type="submit"
                                // type="button"
                                name="save"
                                className="btn-save"
                                style={{
                                  ...sizeColor,
                                  [ "paddingLeft" ]: "0px",
                                }}
                              // onClick={() =>formSave}>
                              >
                                {isReports ? "Submit" : "Save"}
                              </button>&nbsp;&nbsp;
                            </>
                          )}
                          {formMode == "read" && !isEClient && dataSourceID < 250 && (
                            <>
                              <EditIcon style={{ color: "#00aae7" }} />&nbsp;
                              <a
                                href="#"
                                className="no-underline"
                                style={{
                                  ...sizeColor,             
                                  ["paddingLeft"]: "0px",
                                  ["paddingRight"]: "12px",
                                  fontSize:"12px",
                                  pointerEvents: isViewVersion ? 'none' : 'auto',
                                  opacity: isViewVersion ? 0.4 : 1,
                                  cursor: isViewVersion ? 'default' : 'pointer',
                                  
                                }}                               
                                onClick={(e) => formButtonEditRecord(e)}
                              >
                                Edit
                              </a>
                            </>
                          )}
                          {formMode == "write" && !isEClient && dataSourceID <250 && (
                            <>
                              <VisibilityIcon
                                style={{
                                  color: "#00aae7",
                                  margin: "0px 2px 5px 0px",
                                  marginBottom: "1px"
                                }}
                              />&nbsp;
                              <a
                                className="no-underline"
                                href="#"
                                style={{
                                  ...sizeColor,
                                  ["paddingLeft"]: "0px",
                                  ["paddingRight"]: "12px",
                                  fontSize:"12px"
                                }}
                                onClick={(e) => formButtonViewRecord(e)}
                              >
                                View
                              </a>
                            </>
                          )}
                          {dataSourceID === 4 && formMode === "read" && dataSourceID < 250 && (
                            <>
                              <button
                                type="button"
                                style={sizeColor}
                                className="btn"
                                onClick={() => {
                                  handleCommentModal("view");
                                }}
                              >
                                <span className="txt" style={{fontSize:"12px"}}>
                                  All comments
                                  <sup>{commentList.length}</sup>
                                </span>
                              </button>
                            </>
                          )}
                          {(formMode == "read" || formMode == "write") && !isEClient && dataSourceID < 250 && (
                            <>
                              <PersonOutlineIcon style={{ 
                                  color: "#00aae7",
                                  marginBottom: "5px" }} />&nbsp;
                              <a
                                href="#"
                                className="no-underline"
                                style={{
                                  ...sizeColor,
                                  ["paddingLeft"]: "0px",
                                  ["paddingRight"]: "12px",
                                  fontSize:"12px"
                                }}
                                onClick={handleRecordAssignment}
                              >
                                Assign
                              </a>
                            </>
                          )}
                          {(formMode == "read" || formMode == "write") && dataSourceID < 250 && (
                            //!(currRoleId > 4 && currRoleId < 8) && (
                            <>
                              <button
                                type="button"
                                style={{...sizeColor,
                                  ["paddingLeft"]: "0px"}}
                                className="btn"
                                onClick={() => {
                                  handleCommentModal("add");
                                }}
                              >
                                <AddIcon
                                  style={{
                                    stroke: "#00aae7",
                                    strokeWidth: "4px",
                                    marginBottom: "7px"
                                  }}
                                />&nbsp;
                                <span className="txt" style={{fontSize:"11px"}}>
                                  {dataSourceID !== 4
                                    ? "Add comment"
                                    : "Add general comment"}
                                </span>
                              </button>
                            </>
                          )}
                          {formMode == "write" &&
                            SecurityMap("ShowSRRButton") && (
                              <>
                                <ShortcutIcon
                                  style={{
                                    color: "#00aae7",
                                    margin: "0px 2px 5px 0px",
                                  }}
                                />&nbsp;
                                <a
                                  href="#"
                                  style={{
                                    ...sizeColor,
                                    ["paddingLeft"]: "0px",
                                    ["paddingRight"]: "12px",
                                    fontSize:"11px"
                                  }}
                                  onClick={handleWorkflow}
                                >
                                  Submit for review
                                </a>
                              </>
                            )}
                          {formMode == "write" &&
                            SecurityMap("ShowARRButton") && (
                              <>
                                <DomainVerificationIcon
                                  style={{
                                    color: "#00aae7",
                                    margin: "0px 2px 5px 0px",
                                  }}
                                />&nbsp;
                                <a
                                  href="#"
                                  style={{
                                    ...sizeColor,
                                    ["paddingLeft"]: "0px",
                                    ["paddingRight"]: "12px",
                                  }}
                                  onClick={handleWorkflowApprove}
                                >
                                  Approve
                                </a>
                              </>
                            )}
                          {formMode == "write" &&
                            SecurityMap("ShowRWIPButton") && (
                              <>
                                <ReplayIcon
                                  style={{
                                    color: "#00aae7",
                                    margin: "0px 2px 5px 0px",
                                  }}
                                />&nbsp;
                                <a
                                  href="#"
                                  style={{
                                    ...sizeColor,
                                    ["paddingLeft"]: "0px",
                                    ["paddingRight"]: "12px",
                                  }}
                                  onClick={handleWorkflow}
                                >
                                  Return to work-in-process
                                </a>
                              </>
                            )}
                          {formMode == "write" &&
                            SecurityMap("ShowMRRButton") && (
                              <>
                                <BiSave
                                  style={{
                                    color: "#00aae7",
                                    margin: "0px 2px 5px 0px",
                                  }}
                                />&nbsp;
                                <a
                                  href="#"
                                  style={{
                                    ...sizeColor,
                                    ["paddingLeft"]: "0px",
                                    ["paddingRight"]: "12px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    console.log("test");
                                  }}
                                >
                                  Open recently reviewed
                                </a>
                              </>
                            )}
                          {formMode == "write" && dataSourceID < 250 && (
                            <>
                              <RiDeleteBinLine
                                style={{
                                  color: "#00aae7",
                                  margin: "0px 2px 5px 0px",
                                  fontSize:"medium"
                                }}
                              />&nbsp;
                              <a
                                className="no-underline"
                                href="#"
                                style={{
                                  ...sizeColor,
                                  ["paddingLeft"]: "0px",
                                  ["paddingRight"]: "12px",
                                }}
                                onClick={handleRecordDelete}
                              >
                                Delete
                              </a>
                            </>
                          )}

                          {formMode === "read" &&
                            dataSourceID === 4 &&
                            !(
                              Number(currRoleId) > 4 && Number(currRoleId) < 8
                            ) && (
                              <span className="iqvia-color">
                                <span> Compare with </span>
                                <input
                                  type="text"
                                  value={compareWithValue}
                                  onChange={(e) =>
                                    setCompareWithValue(e.target.value)
                                  }
                                />
                                <button
                                  type="button"
                                  style={{
                                    color: "#fff",
                                    backgroundColor: "#00aae7",
                                    width: 100,
                                    height: 22,
                                    padding: 0,
                                    borderRadius: 0,
                                    marginLeft: 5,
                                  }}
                                  className="btn"
                                  onClick={comparePricingRecord}
                                >
                                  Compare
                                </button>
                              </span>
                            )}
                        </div>
                        {/* <hr style={{color:"black",}}/> */}
                        {dataSourceID === 4 && (
                          <div style={{ marginTop: 10, marginLeft: 80 }}>
                            <Link
                              className="iqvia-color txtnew"
                              style={{
                                marginLeft: 5,
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              to={"/"}
                              target="_blank"
                              onClick={(e) => drugSearch(e, "Clinical")}
                            >
                              Clinical
                            </Link>
                            <Link
                              className="iqvia-color txtnew"
                              style={{
                                marginLeft: 5,
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              to={"/"}
                              target="_blank"
                              onClick={(e) => drugSearch(e, "Regulatory")}
                            >
                              Regulatory
                            </Link>
                            <Link
                              className="iqvia-color txtnew"
                              style={{
                                marginLeft: 5,
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              to={"/"}
                              target="_blank"
                              onClick={(e) => drugSearch(e, "HTA")}
                            >
                              HTA
                            </Link>
                          </div>
                        )}
                      { !location.pathname.includes("confirm") && <div
                          style={{
                            marginTop: dataSourceID === 4 ? "2px" : "5px",
                            flex: 1,
                            height: "0.5px",
                            backgroundColor: "black",
                          }}
                        />}
                      </div>
                    </div>
                    {currentView === "Audit" && (
                      <TableGrid
                        tableName="AuditLogTable"
                        isLoading={isSearchgGridLoading}
                        rowData={auditData.rowData}
                        columnDefs={auditData.colDef}
                        pageSize={8}
                      />
                    )}
                    {dataSourceID === 4 && (
                      <PricingView
                        values={values}
                        datasourceName={datasourceName}
                        pricingCompareRecord={pricingCompareRecord}
                      />
                    )}
                    
                    {currentView === "Record" &&
                      dataSourceID !== 4 &&
                      Object.keys(newRows).length > 0
                      &&
                      sections
                        .filter((x) => validateSection(x , false))
                        // .filter(
                        //   (s) =>
                        //     s.parent_section_id == null ||
                        //     s.parent_section_id == 0
                        // )
                        .map((el) => (
                          <div
                            // className="panel-group"
                            className={
                              el.parent_section_id != null
                                ? "panel-sub-group"
                                : el.name == "Economic analysis" ? "panel-group-Economic-analysis" : el.name == "Budget impact analysis" ? "panel-group-impact-analysis" : el.name == "Endpoints and results"? "panel-group-Economic-analysis" : el.id == 161 ? "panel-group-clinical-outcomes" : "panel-group"
                            }
                            // style={{marginLeft: el.name == "Disease" ? "-15px" : ""}}
                            style={{borderTop: el.id==64 || (dataSourceID == 1 && el.name == "Clinical outcome assessments") ? "1px solid gray": "",borderBottom: el.id==61 || el.id==53 ?"none":""}}
                            id={"section-" + el.id.toString()}
                            key={el.id}
                          >     
                            {!(
                              dataSourceID == 5 &&
                              el.name == "Potential implications for industry"
                            ) && (
                              <div
                                className="pull-left"
                                style={{ width: "100%" }}
                              >
                                {el.name == "Information" && location.pathname.includes("confirm") && dataSourceID == 274 && <h2>
                                  Please confirm this subscription</h2>}
                                <h4
                                  className= {el.name == "Approved Indications" ? "panel-title-approved-indication" : "panel-title"}
                                  data-toggle="collapse"
                                  data-target={
                                    "[id='section-" +
                                    el.id.toString() +
                                    "-collapse']"
                                  }
                                  // style={{paddingLeft: el.name == "Disease" ? "13px" : ""}}
                                >
                                  <span
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title=""
                                    style={{
                                      fontSize: el.name == "Internal reference pricing"||"Early engagement"||"Biosimilars"||"Vaccines"||"International reference pricing" ? "12px" : "small",
                                      marginLeft: formMode == "new" && el.name == "General information" ? "12px" : (formMode == "read" || formMode == "write") && el.name == "Record details" ? "12px" : formMode == "new" && el.name == "Regulatory status" ? "12px" : "0px",
                                  }}
                                  >
                                    {el.name.toLowerCase() ==
                                      "type of impact" && dataSourceID == 5
                                      ? "Potential implications for industry"
                                      : dataSourceID < 250 ? el.name : dataSourceID == 274 && el.name !== "Information" ? el.name : "" }
                                  </span>
                                </h4>
                              </div>
                            )}
                      { dataSourceID == 275 && formMode == "write" && 
                         <>
                          <UserRadioButton userClientRole= {userClientRole} values = {values} />
                         </>}
                            {el.name === "Disease" &&
                            // el.id === 49 &&
                            location.pathname.toLowerCase().indexOf("view") >
                              0 ? (
                              <div style={{ margin: "-14px 10px 18px 0px" }}>
                                {diseaseTable()}
                              </div>
                            ) : (
                              <>
                                {el.name.toLowerCase() == "type of impact" &&
                                formMode == "read" &&
                                dataSourceID == 5 ? (
                                  <Policy_PotentialImplications
                                    fieldInfo={newRows[el.id].filter(
                                      (x) =>
                                        validateCriteria(x)
                                    )}
                                    // datasourceName={datasourceName}
                                    // section={el.name}
                                  />
                                ) : (
                                  <div
                                    id={
                                      "section-" +
                                      el.id.toString() +
                                      "-collapse"
                                    }
                                    style={{ clear: "both" }}
                                    className="collapse in show"
                                  >
                                    {newRows[el.id]
                                      ?.filter(
                                        (x) =>
                                          // x.sectionId == el.id &&
                                          validateCriteria(x) &&
                                          x.masterFieldId == null
                                      )
                                      .map((x) => (
                                        <div key={x.id}>
                                          {
                                            masterFieldTypes.includes(
                                              x.fieldTypeId
                                            ) &&
                                              formMode !== "read" && (
                                                <SubPageForm
                                                  field={x}
                                                  values={values}
                                                  fieldChanged={fieldChanged}
                                                  childRows={newRows[el.id]}
                                                  validateCriteria={
                                                    validateCriteria
                                                  }
                                                  RichtextFieldChanged={
                                                    RichtextFieldChanged
                                                  }
                                                  datasourcename={
                                                    datasourceName
                                                  }
                                                  section={el.name}
                                                  datasourceId={dataSourceID}
                                                  masterFieldTypes={
                                                    masterFieldTypes
                                                  }
                                                  openModal={openModal}
                                                  formMode = {formMode || ""}
                                                />
                                              )

                                            // renderType1Detail(x, el)
                                          }                                   
                                          {!masterFieldTypes.includes(
                                            x.fieldTypeId
                                          ) &&
                                            !x.name.includes(
                                              "RegulatoryWatch_ApprovedIndication"
                                            ) &&
                                            !x.name.includes(
                                              "RegulatoryWatch_DosageAndMode"
                                            ) &&
                                              validateSubgroupRelatedFields(el.name, x.name) && (
                                              <>
                                                <FieldView
                                                  field={x}
                                                  values={values}
                                                  fieldChanged={fieldChanged}
                                                  RichtextFieldChanged={
                                                    RichtextFieldChanged
                                                  }
                                                  datasourcename={
                                                    datasourceName
                                                  }
                                                  section={el.name}
                                                  datasourceId={dataSourceID}
                                                  allFields={x.parentFieldId  != null ? rows : newRows[el.id]}
                                                  UpdateLookupField={
                                                    UpdateLookupField
                                                  }
                                                  lookupDisplay={lookupDisplay}
                                                  UpdateNonVersionCol1={
                                                    UpdateNonVersionCol1
                                                  }
                                                  nonVersionCol1={
                                                    nonVersionCol1
                                                  }
                                                  UpdateNonVersionCol2={
                                                    UpdateNonVersionCol2
                                                  }
                                                  nonVersionCol2={
                                                    nonVersionCol2
                                                  }
                                                  datasourceDbName={
                                                    datasourceDbName
                                                  }
                                                  formMode={formMode}
                                                  setFormSavingProgress = {setFormSavingProgress}
                                                ></FieldView>
                                            {
                                              x.name == "PrimaryContact" && dataSourceID == 251 && formMode == "write" &&
                                              <div className="col-sm-12 row form-group">
                                              <label className="col-sm-4 control-label " >Groups</label>
                                                  <div className="col-sm-4">
                                                    <div style ={{padding:"10px 0px 10px 0px"}}>
                                                    <a href={`/admin/list/252/${params.recordID}`} style={{ color: "#00AAE7", paddingTop: "10px" }}>Manage</a>
                                                      </div>
                                                   
                                                    <ul className="col-sm-12" style={{ borderRadius: "0.25rem" }}>
                                                      {selectedLookupGlobe?.ClientGroup?.map((el) => (
                                                        <li
                                                          className="col-sm-12 row"
                                                          style={{
                                                            width: "100%",
                                                            // display: "block",
                                                            border: "1px solid rgba(0, 0, 0, 0.125)",
                                                            position: "relative",
                                                            padding: "0.75rem 1.25rem"
                                                          }}>
                                                          <div className="col-sm-8" style={{ display: "block" ,paddingBottom:"10px"}}>
                                                              <label>
                                                                    {el.Value}
                                                              </label>
                                                          </div>
                                                          <div className="col-sm-4" style={{ display: "block", alignContent: "center" }} >
                                                            <a
                                                              style={{
                                                                color: "rgb(0, 170, 231)"
                                                              }}
                                                              href={`admin/form/252/${el.Id}`}>Edit</a>
                                                          </div>
                                                        </li>
                                                        ))}
                                                    </ul>
                                                  </div>
                                                 
                                              </div> 
                                            }
                                              {
                                                x.name == "UserIds" && dataSourceID == 276 &&
                                                <div>
                                                  <h2>Tags</h2>
                                                  <hr></hr>
                                                </div>
                                              }
                                            {
                                              x.name == "Body" && dataSourceID == 276 && 
                                              <div className ="col-sm-12 row form-group"> 
                                                <label className="col-sm-4 text-end control-label ">Attachments</label>
                                                <div className="col-sm-8">
                                                <input type="file" multiple onChange={handleChangeForFile} />
                                                    {uploadedFiles?.map((fileObj: any, index: number) => (
                                                      <div className="row">
                                                        <ClientNewsFiles
                                                          bufferData={fileObj.FileData}
                                                          type={fileObj.ContentType}
                                                          fileName={fileObj.FileName}
                                                          NewlyAdded={fileObj.NewlyAdded}
                                                          ClientNewsFileId={fileObj.ClientNewsFileId}
                                                          recordId={record_ID} />
                                                        <div className="col-sm-1">
                                                          <FontAwesomeIcon
                                                            icon={faXmark}
                                                            style={{ fontSize: "large", fontWeight: "bold", cursor: "pointer", float: "right" }}
                                                            onClick={(e:any) => {
                                                              setClientNewsFileId(Number(fileObj.ClientNewsFileId));
                                                              handleAttachementDelete(e)
                                                            }} />
                                                        </div>
                                                      </div>
                                                    ))}
                                                </div>                                
                                              </div>
                                            }
                                            {
                                              x.name == "PrimaryContact" && dataSourceID == 251 && formMode == "write" &&
                                              <div className="col-sm-12 row form-group">
                                              <label className="col-sm-4 control-label">Client messages</label>
                                              <a className="col-sm-8" href="/admin/list/252" style={{color:"#00AAE7",paddingTop:"10px"}}>Manage</a>
                                              </div>
                                            }
                                            
            
                                                {dataSourceID == 1 &&
                                                 
                                                  ((el.name ==
                                                    "Reviewed indication and manufacturer’s claims" &&
                                                    x.name ==
                                                      "IsSubgroupEnabled") ||
                                                    (el.name ==
                                                      "Final recommendation" &&
                                                      x.name ==
                                                        "FinalRecommendation_ID")) &&
                                                  formMode !== "read" && (
                                                    <HTARecord_Form_Subgroup
                                                      field={x}
                                                      values={values}
                                                      fieldChanged={
                                                        fieldChanged
                                                      }
                                                    />
                                                  )}
                                                {dataSourceID == 1 &&
                                                  formMode == "read" &&
                                                  ((el.name ==
                                                    "Reviewed indication and manufacturer’s claims" &&
                                                    x.name ==
                                                      "IsSubgroupEnabled") ||
                                                    (el.name ==
                                                      "Final recommendation" &&
                                                      x.name ==
                                                        "FinalRecommendation_ID")) && (
                                                    <HTARecord_SubGroup
                                                      datasourceName={
                                                        datasourceName
                                                      }
                                                      section={el.name}
                                                      fieldID={x.id}
                                                    />
                                                  )}
                                              </>
                                            )}
                                          {x.name == "RWESupportingEvidence" && dataSourceID == 1 && (
                                            <>
                                              <RWE
                                                fieldInfo={rows.filter(
                                                  (x) => x.sectionId == el.id
                                                  // &&
                                                  // validateCriteria(x)
                                                )}
                                                datasourceName={datasourceName}
                                                section={el.name}
                                                showEmptyFieldsFlag={showEmptyFieldsFlag}
                                              />
                                              <div style={{margin:"1%"}}>
                                                <hr />
                                                <b>RWE critique </b>
                                              </div>                                            
                                            </>                                           
                                          )}
                                        </div>
                                      ))}
                                    
                                  </div>
                                )}
                                {                                                                            
                                  dataSourceID == 277 && <DynamicFieldForm 
                                                            fieldId={Number(values["Field_CriteriaField_ID"])} 
                                                            datasourcename={datasourceName} 
                                                            section={el.name} 
                                                            datasourceId={dataSourceID} 
                                                            values={values} 
                                                            fieldChanged={fieldChanged} 
                                                            RichtextFieldChanged={RichtextFieldChanged}
                                                            fieldCriteriaDatasourceID = {params.fieldCriteriaDatasourceID}
                                                            dynamicField = {dynamicField}
                                                            />
                                }
                                  {
                                    dataSourceID == 264 && <ShowRule 
                                                    dataSourceID={dataSourceID} 
                                                    fieldCriteriaDatasourceID={values?.DatasourceId} 
                                                    fieldId={record_ID} 
                                                    recordID={record_ID} />
                                  }
                              </>
                            )}
                            {formMode == "read" && (
                              <SubPageViewSummary
                                rows={newRows[el.id]}
                                el={el}
                                dataSourceID={dataSourceID}
                                datasourceName={datasourceName}
                                validateCriteria={validateCriteria}
                                RichtextFieldChanged={RichtextFieldChanged}
                                values={values}
                                fieldChanged={fieldChanged}
                                showEmptyFieldsFlag = {showEmptyFieldsFlag}
                              />
                            )}
                           

                            {/* {el.name.toLowerCase() == "type of impact" &&
                              dataSourceID == 5 && (
                                <Policy_PotentialImplications
                                  fieldInfo={rows.filter(
                                    (x) =>
                                    x.sectionId == el.id &&
                                      validateCriteria(x)
                                  )}
                                  // datasourceName={datasourceName}
                                  // section={el.name}
                                />
                              )} */}
                          </div>
                        ))}

                        {dataSourceID == 274 && formMode == "read" && 
                            <button
                            type="button"
                            onClick= {() =>
                            {
                              const path = `/admin/form/${dataSourceID}${record_ID ? `/${record_ID}` : ""}`;
                              navigate.push({pathname:path,state: {values:values,selectedValues :selectedLookupGlobe }})
                            }}
                            style={{
                              border:"none",
                              background:"rgb(0, 170, 231)",
                              color:"white",
                              height:"40px",
                              width:"150px",
                              fontSize:"15px",
                              marginLeft:"180px"

                            }}>{"<Back to Edit Form"}</button>}
                             { dataSourceID == 274 && formMode == "read" && 
                            <button style={{
                              marginRight:"70%",
                              float :"right",
                              border:"none",
                              background:"rgb(0, 170, 231)",
                              color:"white",
                              height:"40px",
                              width:"70px",
                              fontSize:"15px"
                            }}>Save</button>}
                            
                    {dataSourceID > 250 && (dataSourceID != 274 && dataSourceID != 268 && dataSourceID != 269 && dataSourceID != 272 && dataSourceID !== 254 && dataSourceID !== 255 && dataSourceID !== 262  && (formMode == "new" || formMode == "write")) &&
                      <div style={{ paddingLeft: "290px", paddingTop: "10px" }}>
                        <button style={{
                          marginRight: "70%",
                          border: "none",
                          background: "rgb(0, 170, 231)",
                          color: "white",
                          height: "40px",
                          width: "70px",
                          fontSize: "15px"
                        }}>{ (dataSourceID == 266 && formMode == "new") ? "Create" :  "Save"}</button>
                      {
                        dataSourceID == 276 && formMode =="write" &&
                        <button type="button" style={{
                          marginLeft: "-690px",
                          zIndex: "10",
                          border: "none",
                          background: "rgb(0, 170, 231)",
                          color: "white",
                          height: "40px",
                          width: "70px",
                          fontSize: "15px"
                        }} onClick={handleClientNewsDelete}>Delete</button>
                        }
                        {
                          dataSourceID == 277 && formMode == "new" &&
                        <button
                          name="createNew"
                          style={{
                            border: "none",
                            background: "rgb(0, 170, 231)",
                            color: "white",
                            height: "40px",
                            width: "220px",
                            fontSize: "15px",
                            marginLeft: "-690px",
                          }}> Save and create new rule </button>
                        }
                        {
                          [256,257,258,259,265,270].includes(dataSourceID) && (formMode == "new" || formMode == "write") &&
                          <button type="button" style={{
                            marginLeft: "-690px",
                            zIndex : "10",
                            border: "none",
                            background: "rgb(0, 170, 231)",
                            color: "white",
                            height: "40px",
                            width: "70px",
                            fontSize: "15px"
                          }} onClick={() => navigate.push(`/admin/list/${dataSourceID}`)}>Cancel</button>
                        }
                        {
                          [256,257,258,259,265,270].includes(dataSourceID) && formMode == "write" &&
                          <button type="button" style={{
                            marginLeft: "40px",
                            border: "none",
                            background: "rgb(0, 170, 231)",
                            color: "white",
                            height: "40px",
                            width: "70px",
                            fontSize: "15px"
                          }} onClick={async(e) => {
                            if(dataSourceID == 265){
                              handleDisableExportDelete(e);
                            }else{
                              await DeleteCall(`/api/admin/delete/table/${dataSourceID}/${record_ID}`);
                              navigate.push(`/admin/list/${dataSourceID}`);
                            }
                          }}>Delete</button>
                        }
                      </div>
                    }
                    {
                      (dataSourceID === 255 || dataSourceID == 262)  &&
                      <div style={{ paddingLeft: "290px", paddingTop: "10px" }}>
                      <button id="diseaseSaveButton" style={{
                        border: "none",
                        background: "rgb(0, 170, 231)",
                        color: "white",
                        height: "40px",
                        width: "70px",
                        fontSize: "15px"
                      }}>Save</button>

                    { dataSourceID == 255 &&  <button type="button" style={{
                            marginLeft: "55px",
                            border: "none",
                            background: "rgb(0, 170, 231)",
                            color: "white",
                            height: "40px",
                            width: "70px",
                            fontSize: "15px"
                          }} onClick={() => { navigate.push("/admin/list/255") }}>Cancel</button>
                        }
                        {
                        formMode == "write" && 
                          <button type="button" style={{
                            marginLeft: "40px",
                            border: "none",
                            background: "rgb(0, 170, 231)",
                            color: "white",
                            height: "40px",
                            width: "70px",
                            fontSize: "15px"
                          }} onClick={async() => {
                            await DeleteCall(`/api/admin/delete/table/${dataSourceID}/${record_ID}`);
                            navigate.push(`/admin/list/${dataSourceID}`);
                          }}>Delete</button>
                      }
                    </div>
                      }
                     
                     { dataSourceID === 254 && 
                      <div style={{ paddingLeft: "290px", paddingTop: "10px" }}>
                        <button id="displayEditButton" style={{
                          border: "none",
                          background: "rgb(0, 170, 231)",
                          color: "white",
                          height: "40px",
                          width: "70px",
                          fontSize: "15px"
                        }}>Save</button>

                        <button type="button" style={{
                              marginLeft: "55px",
                              border: "none",
                              background: "rgb(0, 170, 231)",
                              color: "white",
                              height: "40px",
                              width: "70px",
                              fontSize: "15px"
                            }} onClick={() => { navigate.push("/admin/list/254") }}>Cancel</button>

                        {
                          formMode == "write" && <>
                           
                            <button type="button" style={{
                              marginLeft: "40px",
                              border: "none",
                              background: "rgb(0, 170, 231)",
                              color: "white",
                              height: "40px",
                              width: "70px",
                              fontSize: "15px"
                            }} onClick={() => {
                              DeleteCall(`/api/admin/delete/table/${dataSourceID}/${record_ID}`);
                              alert("Successfully deleted display name.");
                              navigate.push(`/admin/list/${dataSourceID}`);
                            }}>Delete</button>
                          </>
                        }
                      </div>
                     }
                    {
                      (dataSourceID == 268 || dataSourceID == 269 || dataSourceID == 272) &&
                      <div style={{paddingLeft:"290px",paddingTop:"10px"}}>
                        <button style={{
                          border: "none",
                          background: "rgb(0, 170, 231)",
                          color: "white",
                          height: "40px",
                          width: "70px",
                          fontSize: "15px"
                        }}>{"Submit"}</button>
                        <button type="button" style={{
                          marginLeft:"5px",
                          border: "none",
                          background: "rgb(0, 170, 231)",
                          color: "white",
                          height: "40px",
                          width: "70px",
                          fontSize: "15px"
                        }} onClick={() => navigate.push('/')}>Cancel</button>
                      </div>
                    }

                  

                 
                          
                             { dataSourceID == 274 &&(formMode == "new" ||formMode == "write") && 
                            <button type="submit" style={{
                              
                              marginLeft:"290px",
                              border:"none",
                              background:"rgb(0, 170, 231)",
                              color:"white",
                              height:"40px",
                              width:"150px",
                              fontSize:"15px"
                              }}
                              name="preview"
                                                      
                            // onClick = {(e) => {
                              
                            //   navigate.push({pathname:`/admin/confirm/${dataSourceID}`,state: {values:values,selectedValues :selectedLookupGlobe }})
                            // } 
                          // }                           

                            >{selectedLookupGlobe.DataSourceIds?.length == 1 
                              && selectedLookupGlobe.DataSourceIds[0].Id == 1 ? 
                                "Preview HTA >" : "Confirm"}</button>}

                    {dataSourceID == 2 &&
                      showPrePopulatedFieldsModal && (
                        <PrePopulatedFieldsPopup
                          handleCloseRecDel={
                            handleCloseForPrepopulatedFields
                          }
                          showPrePopulatedFieldsModal={
                            showPrePopulatedFieldsModal
                          }
                          setValues={userContext.setValues}
                        />
                      )}
                    <div className="end-of-record"></div>
                    {((commentList &&
                    commentList.length > 0 &&
                    currentView === "Record" &&
                    dataSourceID !== 4) || showEmptyFieldsFlag  )? (
                      <div ref={myRef}>
                        <Comments
                          commentList={commentList}
                          clientList={clientList}
                          commentTableName={commentTableName}
                          findComments={findComments}
                          view={formMode}
                        />
                      </div>
                    ) : null}
                    {
                      (dataSourceID == 264 ||dataSourceID == 277 ) &&
                      <button
                        onClick={() => navigate.push(`/admin/list/263`)}
                        style={{ background: "none", border: "none", color: "rgb(0, 170, 231)" }}
                      >
                        Back to List
                      </button>
                    }
                    {currentView === "Attachments" && (
                      <>
                        {!loadingFile ? (
                          <div className="attachmentContainer">
                            <div
                              className="attachmentsList row"
                              style={{ display: "block" }}
                            >
                              {Object.entries(attachmentList).map((el, key) => (
                                <div
                                  style={{ borderRadius: "0px" }}
                                  className="attachmentElement row mt-2"
                                  key={key + "_" + el[1].id}
                                >
                                  <div className="left col-11">
                                    <div
                                      className="title"
                                      onClick={() =>
                                        downLoadAttachment(
                                          el[1].fileData,
                                          el[1].fileName,
                                          el[1].id
                                        )
                                      }
                                      style={
                                        el[1].date === null
                                          ? { padding: 15 }
                                          : {}
                                      }
                                    >
                                      {el[1].fileName}
                                    </div>
                                    {el[1].date !== null && (
                                      <div className="date">
                                        Uploaded on : {el[1].date}
                                      </div>
                                    )}
                                  </div>
                                  {formMode !== "read" && (
                                    <div className="right col-1">
                                      <RiDeleteBinLine
                                        onClick={() =>
                                          handleClickDelete(el[1].id)
                                          //deleteAttachment(el[1].id)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            {formMode !== "read" && (
                              <div className="attachmentBtn">
                                <>
                                  <input
                                    color="primary"
                                    type="file"
                                    multiple
                                    id="icon-button-file"
                                    style={{ display: "none" }}
                                    onChange={(e) => uploadFiles(e)}
                                  />
                                  <label htmlFor="icon-button-file">
                                    <Button
                                      variant="contained"
                                      component="span"
                                      //size="medium"
                                      //color="rgb(0, 170, 231)"
                                      style={{backgroundColor:"rgb(0,170,231)",border:"1px solid rgb(0,170,231)",borderRadius:"0px",fontSize:"12px",height:"30px",width:"100%",paddingLeft:"5px",paddingRight:"5px"}}
                                    >
                                      Add File(s)
                                    </Button>
                                  </label>
                                  
                                </>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "300px",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", textAlign: "center" }}>
                              <CircularProgress />
                            </Box>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <br />
                  {dataSourceID == 276 && (
                    <a className = "iqvia-color" href={"/news/list"}>Back to List</a>
                  )}
                  <br />
                </form>
              </div>
            </div>
          </div>
        </>
      )}

      <Snackbar
        open={mandoryFieldMissing}
        autoHideDuration={6000}
        onClose={() => setMandoryFieldMissing(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setMandoryFieldMissing(false)}
          severity="warning"
          sx={{ width: "100%" }}
        >
          One of the form fields is missing or invalid.
        </Alert>
      </Snackbar>

      {isLoading && <div style={{marginLeft:"3em"}}>Fields are being loaded...</div>}
      <CommentModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        clientList={clientList}
        record_ID={record_ID}
        commentTableName={commentTableName}
        dataSourceID={dataSourceID}
        findComments={findComments}
        commentModalView={commentModalView}
        commentList={commentList}
      />
      {!isLoading && isAdminPage && !isReports && dataSourceID !== 277 && dataSourceID !== 254 && dataSourceID !== 267  && (
        <a href={"/admin/list/" + dataSourceID}>Back to list</a>
      )}
           {!isLoading && isAdminPage && !isReports && dataSourceID == 267  && (
        <a href={"/admin/list/266"}>Back to List</a>
      )}
        
      {/* {currentView === "Audit" && (
        <TableGrid
          isLoading={isSearchgGridLoading}
          rowData={auditData.rowData}
          columnDefs={auditData.colDef}
          pageSize={100}
        />
      )} */}
      {anchorRecDel && (
        <DraggablePopover
          title="Delete confirmation"
          anchorEl={anchorRecDel}
          popupBody={<p>Are you sure you want to delete this record?</p>}
          buttonsArray={["Yes"]}
          // btnCallbacks={[datasourceRecordDelete]}
          btnCallbacks={[deletionPart]}
          onPopupClose={handleCloseRecDel}
        ></DraggablePopover>
      )}
      {attachmentDel && (
        <DraggablePopover
          title="Delete confirmation"
          anchorEl={attachmentDel}
          popupBody={<p>Are you sure you want to delete this attachment?</p>}
          buttonsArray={["Yes"]}
          // btnCallbacks={[datasourceRecordDelete]}
          btnCallbacks={[deleteAttachementFile]}
          onPopupClose={handleCloseAttachmentDel}
          keyName={"clientNews"}
        ></DraggablePopover>
      )}
        {clientNewsDel && (
        <DraggablePopover
          title="Delete confirmation"
          anchorEl={clientNewsDel}
          popupBody={<p>Are you sure you want to delete this news article?</p>}
          buttonsArray={["Yes"]}
          // btnCallbacks={[datasourceRecordDelete]}
          btnCallbacks={[deleteClientNews]}
          onPopupClose={handleClientNewsDel}
          keyName={"clientNews"}
        ></DraggablePopover>
      )}

      {disableExportDel && (
        <DraggablePopover
          title="Delete confirmation"
          anchorEl={disableExportDel}
          popupBody={<p>Are you sure you want to remove this mapping?</p>}
          buttonsArray={["Yes"]}
          // btnCallbacks={[datasourceRecordDelete]}
          btnCallbacks={[deletionPart]}
          onPopupClose={handleCloseDisableExportDel}
          keyName = {"diableExport"}
        ></DraggablePopover>
      )}
           
      {anchorWorkflowChange && (
        <DraggablePopover
          title="Change confirmation"
          anchorEl={anchorWorkflowChange}
          popupBody={
            <table style={{ borderCollapse: "collapse" }}>
              <thead></thead>
              <tbody>
                {!(values["Status_ID"] == Status.WIP || values["Status_ID"] == Status.WIPR) && (
                  <tr id="ChangeReasonRow" style={{ verticalAlign: "top" }}>
                    <td style={{ paddingBottom: "1.5em", width: "30%" }}>
                      Reason for change:
                    </td>
                    <td>
                      <div className="autocomplete">
                        <Autocomplete
                          className="autocomplete"
                          disablePortal
                          id="combo-box-demo"
                          onChange={(event: any, newValue: string | null) => {
                            setSelectedWorkflowReason(newValue.ID);
                          }}
                          options={workflowList}
                          sx={{ width: 289 }}
                          renderInput={(params) => <TextField {...params} />}
                          // value={clientName}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                <tr style={{ verticalAlign: "top" }}>
                  <td>Message (optional):</td>
                  <td>
                    <textarea
                      id="WorkflowChangeMessage"
                      onChange={(e) => {
                        setWorkflowMessage(e.target.value);
                      }}
                      style={{border:"1px solid #d5d5d5"}}
                      className="text-area-custom-style"
                      cols="40"
                      rows="5"
                    ></textarea>
                  </td>
                </tr>
                <tr id="WorkflowLogWorkRow" style={{ verticalAlign: "top" }}>
                  <td>Log work (hour):</td>
                  <td >
                    <input
                      type="text"
                      onChange={(e) => validateHourLog(e, "hrs")}
                      id="WorkflowLogWorkHH"
                      placeholder="HH"
                      style={{border:"1px solid #d5d5d5",height:"25px",width:"141px"}}
                    />
                    &nbsp;&nbsp;
                    <input
                      type="text"
                      onChange={(e) => validateHourLog(e, "mnt")}
                      id="WorkflowLogWorkMM"
                      placeholder="MM"
                      style={{border:"1px solid #d5d5d5",height:"25px",width:"141px"}}
                    />
                  </td>
                </tr>
                <tr id="WorkflowLogWorkErrorMessage">
                  <td></td>
                  <td>
                    <span className="validation-error" style={{ color: "red" }}>
                      {minuteHourValidation}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          }
          buttonsArray={["Ok"]}
          btnCallbacks={[confirmWorkflowChange]}
          onPopupClose={handleCloseWorkflow}
        ></DraggablePopover>
      )}

      {formSavingProgress && (
        <div className="search-grid-result loadingdiv" >
          <div className="loadingResult-popup">
              <div className="loadingResult-content">
                  <div>
                      <br />
                      <div>
                          <Spinner animation="border" style={{color: "#00aae7"}} />
                      </div>
                      <br />
                      <h4 className="saving-wait">Saving, please wait...</h4>
                  </div>
              </div>
          </div>
          <div className="loadingResult-Background"></div>
        </div>
      )}
      {editOnProgress && (
        <div className="search-grid-result loadingdiv" >
          <div className="loadingResult-popup">
              <div className="loadingResult-content">
                  <div>
                      <br />
                      <div>
                          <Spinner animation="border" style={{color: "#00aae7"}} />
                      </div>
                      <br />
                      <h4 className="saving-wait">Please wait...</h4>
                  </div>
              </div>
          </div>
          <div className="loadingResult-Background"></div>
        </div>
      )}

      <Modal show={showAssignModal} onHide={assignModalClose}>
        <Modal.Header style={{ backgroundColor: "#FAFAFA" }}>
          <Modal.Title>
            <div id="modal">
              <h4
                style={{
                  // marginLeft: "10px",
                  // marginTop: "15px",
                  float: "left",
                  fontWeight: "bold",
                }}
              >
                Assign users
              </h4>
            </div>
          </Modal.Title>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => assignModalClose()}
          />
        </Modal.Header>
        <Modal.Body
        // style={{ overflowY: "auto", maxHeight: "70vh", overflowX: "hidden" }}
        >
            <div className="bodyContainer">
              <TabContext value={typeValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleAssignType}
                    aria-label="wrapped label tabs example"
                    centered
                  >
                    <Tab label="Both" value="1" />
                    <Tab label="Associate" value="2" />
                    <Tab label="Reviewer" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className={"col-sm-12 row form-group"}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Associate
                    </label>
                    <div className="col-sm-9 text-end control-label">
                      <div className="autocomplete">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={selectedAssociate}
                          onChange={(event: any, newValue: AdminObj ) => {
                            setSelectedAssociate(newValue);
                            setAssignmentValidation("");
                          }
                          }
                          open={userOpenAssociate}
                          onOpen={() => {
                            setUserOpenAssociate(true);
                          }}
                          onClose={() => {
                            setUserOpenAssociate(false);
                          }}
                          options={userList}
                          getOptionLabel={(user) => user.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="select"
                              variant="outlined"
                            />
                          )}
                          renderOption={renderOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-sm-12 row form-group"} style={{marginTop:"15px"}}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Due date
                    </label>
                    <div className="col-sm-9" style={{position:"relative"}}>
                    <DatePicker
                        id="dateAssociate1"
                        value={
                          associateDueDate == "" || associateDueDate == undefined
                            ? undefined
                            : dayjs(associateDueDate)
                        }
                        format="MM-DD-YYYY"
                        allowClear={true}
                        onChange={(e) => {setAssociateDueDate(createDate(e)); setAssignmentValidation('');}}
                      />
                    </div>
                  </div>
                  <div className={"col-sm-12 row form-group"} style={{marginTop:"12px"}}>
                    <label className={"col-sm-3 text-end col-form-label"} style={{marginTop:"3px"}}>
                      Reviewer
                    </label>
                    <div className="col-sm-9 text-end control-label">
                      <div className="autocomplete">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={selectedReviewer}
                          onChange={(event: any, newValue: string | null) => {
                            setSelectedReviewer(newValue);
                            setAssignmentValidation("");
                          }}
                          open={userOpenReviewer}
                          onOpen={() => {
                            setUserOpenReviewer(true);
                          }}
                          onClose={() => {
                            setUserOpenReviewer(false);
                          }}
                          options={userList}
                          getOptionLabel={(userList) => userList.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="select"
                              variant="outlined"
                            />
                          )}
                          renderOption={renderOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-sm-12 row form-group"} style={{marginTop:"14px"}}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Due date (review)
                    </label>
                    <div className="col-sm-9 control-label">
                      <DatePicker
                        id="dateReviewer1"
                        // type="date"
                        value={
                          reviewerDueDate == "" || reviewerDueDate == undefined
                            ? undefined
                            : dayjs(reviewerDueDate)
                        }
                        onChange={(d:any) =>{ 

                          setReviewerDueDate(createDate(d)); setAssignmentValidation('');}}
                          format="MM-DD-YYYY"
                        sx={{ width: 220 }}
                        placeholder="Due date (reviewer)"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className={"col-sm-12 row form-group"}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Associate
                    </label>
                    <div className="col-sm-9 text-end control-label">
                      <div className="autocomplete">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={selectedAssociate}
                          onChange={(event: any, newValue: AdminObj) => {
                            setSelectedAssociate(newValue);
                            setAssignmentValidation("");
                          }}
                          open={userOpenAssociate}
                          onOpen={() => {
                            setUserOpenAssociate(true);
                          }}
                          onClose={() => {
                            setUserOpenAssociate(false);
                          }}
                          options={userList}
                          getOptionLabel={(userList) => userList.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="select"
                              variant="outlined"
                            />
                          )}
                          renderOption={renderOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-sm-12 row form-group"} style={{marginTop:"15px"}}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Due date
                    </label>
                    <div className="col-sm-9 control-label">
                      <DatePicker
                        id="dateAssociate"
                        value={
                          associateDueDate == "" || associateDueDate == undefined
                            ? undefined
                            : dayjs(associateDueDate)
                        }
                        format="MM-DD-YYYY"
                        allowClear={true}
                        onChange={(e:any) => {
                          setAssociateDueDate(createDate(e)); setAssignmentValidation('');}}
                        placeholder="Due date (associate)"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div className={"col-sm-12 row form-group"}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Reviewer
                    </label>
                    <div className="col-sm-9 text-end control-label">
                      <div className="autocomplete">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={selectedReviewer}
                          onChange={(event: any, newValue: AdminObj) => {
                            setSelectedReviewer(newValue);;
                            setAssignmentValidation("");
                          }}
                          open={userOpenReviewer}
                          onOpen={() => {
                            setUserOpenReviewer(true);
                          }}
                          onClose={() => {
                            setUserOpenReviewer(false);
                          }}
                          options={userList}
                          getOptionLabel={(userList) => userList.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="select"
                              variant="outlined"
                            />
                          )}
                          renderOption={renderOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-sm-12 row form-group"} style={{marginTop:"15px"}}>
                    <label className={"col-sm-3 text-end col-form-label"}>
                      Due date (review)
                    </label>
                    <div className="col-sm-9 control-label">
                      <DatePicker
                        id="dateReviewer"
                        // type="date"
                        value={
                          reviewerDueDate == "" || reviewerDueDate == undefined
                            ? undefined
                            : dayjs(reviewerDueDate)
                        }
                        format="MM-DD-YYYY"
                        onChange={(d) =>{ 
                          setReviewerDueDate(createDate(d)); setAssignmentValidation('');}}
                        placeholder="Due date (reviewer)"
                      />
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
              <div className={"col-sm-12 row form-group"}>
                <label className={"col-sm-3 text-end col-form-label"}>
                  Comment
                </label>
                <div className="col-sm-9 control-label">
                  <textarea
                    id="form-comment"
                    value={enteredComment}
                    onChange={(e) => setEnteredComment(e.target.value)}
                    placeholder="Enter description"
                    className="form-control"
                    style={{
                      width: "300px",
                      height: "100px",
                      marginLeft: "1em",
                    }}
                  ></textarea>

              </div>
            </div>
            <div className={"col-sm-12 row form-group"}>
              <label className={"col-sm-3 text-end control-label"}></label>
              <div className="col-sm-9 control-label">
                <span className="validation-error" style={{ color: "red" }}>
                  {assignmentValidation}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span
            className="spanBtn"
            onClick={() => assignModalClose()}
            style={{ cursor: "pointer" }}
          >
            Cancel
          </span>
          <span>|</span>
          <span
            className="spanBtn"
            onClick={() => recordAssignment()}
            style={{ cursor: "pointer" }}
          >
            Assign
          </span>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={openRecordCreated}
        autoHideDuration={6000}
        onClose={handleRecordCreate}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleRecordCreate}
          severity="success"
          sx={{ width: "100%" }}
        >
          Record created successfully
        </Alert>
      </Snackbar>
      <div className="disclaimer-footer">
        <span style={{ fontSize: "12px" }}>
          Copyright © 2023-2024 IQVIA. All Rights Reserved. Application is best
          viewed in Microsoft Edge / Google Chrome.
        </span>
      </div>
    </>
  );
}

export default SectionView;

interface IPrePopulatedFieldsPopup {
  showPrePopulatedFieldsModal : any,
  handleCloseRecDel:any, 
  setValues: (values: any) => void
}


interface IfieldsPopulated {
  [key:string] : boolean;
}

const PrePopulatedFieldsPopup = ({
  showPrePopulatedFieldsModal,
  handleCloseRecDel,
  setValues,
}: IPrePopulatedFieldsPopup) => {
  const [NCT, setNCT] = useState<String>("");
  const [device, setDevice] = useState<boolean>(false);
  const [NCTcheck, setNCTcheck] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldsPopulated, setFieldspopulated] = useState<IfieldsPopulated>({
    TrialRegistrySource_ID: true,
    Acronym: true,
    TrialStatus_ID: true,
    Name: true,
    StartDate: true,
    SubDate: true,
    StudyCompletionDate: true,
    ClinicalTrialsId: true,
    RegistryLink: true,
    LastVerifiedDate: true,
    ClinicalData_SubmittingCompany: true,
    ClinicalData_Aim: true,
    ClinicalDataType_ID: true,
    TreatmentAllocation_ID: true,
    StudyControl_ID: true,
    Design_ID: true,
    TrialPhase_ID: true,
    ArmsDescription: true,
    Disease: true,
    ClinicalData_Drug: true,
    ClinicalData_DrugControl: true,
    "ArmsCohorts": true,
    "ClinicalData_Endpoints": true,
    Masking_ID: false,
  });

  const dispatch = useAppDispatch();

  const [show,setShow] = useState<boolean>(false);
  const [isExist, setExist] = useState<boolean>(false);

  // const 

  const loadData = async () => {
    const resp : any = await apiCall(`/api/aact/isExist/${NCT}`);
    const nctExist = resp.data
    if(nctExist){
      setExist(true)
    }else {
      setLoading(true);
      if (!NCT) setNCTcheck(true);
      else setNCTcheck(false);
      if (NCT) {
        const res = await apiCall(`/api/aact/${NCT}`);
        setValues(res.data?.values || {});
        dispatch(setSelectedLookupGlobe(res.data["multi_select_values"] || {}));
        let newfieldsPopulated  : IfieldsPopulated= {...fieldsPopulated};
        Object.keys(fieldsPopulated).forEach((x) => {
          if (!res.data["values"][x] && !res.data["multi_select_values"][x])
            newfieldsPopulated[x] = false;
          else newfieldsPopulated[x] = true;
        });
        setFieldspopulated(newfieldsPopulated);
        setLoading(false);
        setShow(true);
        setExist(false)
        // handleCloseRecDel();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <DraggablePopover
        title="Prepopulate fields from clinicaltrials.gov"
        anchorEl={showPrePopulatedFieldsModal}
        popupBody={
          <div>
            <div className="row col-md-12">
              <div style={{display:"contents"}}>
                <input
                  className="form-control"
                  placeholder={"Plese enter the NCT number here"}
                  onChange={(e) => setNCT(e.target.value)}
                  style={{ marginLeft: "40px",width:"50%" }}
                />
              </div>

              <button
                style={{
                  backgroundColor: "#428bca",
                  borderColor: "#428bca",
                  marginLeft: "40px",
                  width: "6%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center"
                }}
                onClick={loadData}
              >
                Load
              </button>
           
              {NCTcheck && (
                <span
                  style={{
                    color: "#dc3545",
                    paddingLeft: "320px",
                    fontWeight: "bold",
                  }}
                >
                  NCT number is mandatory to pull the info from
                  clinicaltrials.gov
                </span>
              )}
            </div>
            {
                isExist && <h6 style={{marginTop:"10px"}}>
                  &nbsp;&nbsp;This NCT already exists in the system
                </h6>
              }

            {
              show && 
              <>
              <a
                href={`https://clinicaltrials.gov/study/${NCT}`}
                style={{ color: "rgb(0, 170, 231)" }}
                onClick = {() => window.open(`https://clinicaltrials.gov/study/${NCT}`)}
              >
                Open in clinicaltrials.gov
              </a>
              <div>
                <h3 style={{ textAlign: "justify" }}>Fields populated:</h3>
                <div>
                  <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
                    {Object.keys(fieldsPopulated)
                      .filter((x) => fieldsPopulated[x] == true)
                      .map((el) => (
                        <li>{el}</li>
                      ))}
                  </ul>
                </div>
              </div>
              <div>
                <h3 style={{ textAlign: "justify" }}>Fields not available:</h3>
                <div>
                  <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
                    {Object.keys(fieldsPopulated)
                      .filter((x) => fieldsPopulated[x] == false)
                      .map((el) => (
                        <li>{el}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </>
            }
         
          </div>
        }
        buttonsArray={[]}
        btnCallbacks={[]}
        onPopupClose={handleCloseRecDel}
      ></DraggablePopover>
    </>
  );
};

interface Iprop{
  userClientRole :  IuserClientRole[],
  values : any
}

const UserRadioButton = ({ userClientRole, values }: Iprop) => {
  const userContext = useContext(UserContext);
  const [checked, setChecked] = useState<number>(0);
  const [current, setCurrent] = useState<number>(0);
  const [accountId, setAccountId] = useState<number>(null);
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const dispatch = useAppDispatch();
  const params : any = useParams()

  useEffect(() => {
    const getcurrUser = async () => {
      const currUser: any = await apiCall(`/api/admin/currentUser/${params.recordID}`);
      setCurrent(currUser.data.ID);
      userClientRole.forEach((x, i) => {
        if (x.Id == Number(currUser.data.ID)) {
          setAccountId(x.Id);
          setCurrent(i);
          setChecked(i);
        }


      })
      // setChecked(index);
    }
    getcurrUser();
  }, [])

  const changeAccount = (index: number) => {

    setChecked(index);
    const account = userClientRole.filter((x, i) => i == index)[0];
    const accountInfo = {
      Account_ID: account.Id,
      Name: account.UserName,
      Client_ID: account.ClientId,
      Role_ID: account.RoleId,
      IsActive: account.IsActive
    };
    const ClientIds = account.ClientGroupId?.split(',') || [];
    const ClientGroups = account.ClientGroups?.split(',') ;
    let ClientGroup : IselectedLookup[] = []
    if (ClientGroups)
      ClientGroup = ClientIds.map((el, i) => ({
        Id: Number(el),
        Value: ClientGroups[i]
      }));

    let _selectedLookupGlobe = { ...selectedLookupGlobe };

    _selectedLookupGlobe["Client_Group"] = ClientGroup;
    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    userContext.setValues({ ...values, ...accountInfo });

  }
  return <div style={{ textAlign: "center" }}>
    <label style={{ fontSize: "14px" }}>User Account : </label>
    <span style={{ borderRadius: "8px", border: "1px solid silver", padding: "5px 2px 3px" }}>
      {userClientRole.map((x) => x.UserName).map((el, i) => (
        <label>
          <input id={el} name="SelectedUserAccount" type="radio" style={{ display: "none" }} />
          <label style={{ fontSize: "14px", color: checked == i ? "white" : "black", background: checked == i ? "#34b2e3" : "white", fontWeight: i == current ? "bold" : "normal", borderRadius: "8px", paddingLeft: "8px", paddingRight: "8px" }} htmlFor={el} onClick={() => changeAccount(i)}>{el}</label>
        </label>
      ))}
    </span>

  </div>
}


// const renderType1Detail = (x: Field, el: Section, parentObject?: any) => {
//   if (!parentObject) {
//     parentObject = values[x.name];
//     if (!parentObject) {
//       parentObject = [];
//       values[x.name] = parentObject;
//       fieldChanged();
//     }
//   }
//   return (
//     <>
//       <div style={{ overflow: "hidden" }}>
//         {formMode !== "read" && (
//           <h5 className="float-left">
//             {x.name == "ReimbursementStatus_ProductStrength"
//               ? "Product Storage"
//               : x.label.replace("-", "")}
//           </h5>
//         )}
//         <div className="float-end">
//           {formMode != "read" && (
//             <button
//               type="button"
//               className="btn btn-small"
//               onClick={() => {
//                 // parentObject.push({});
//                 fieldChanged();
//                 endPointDisplay(x, parentObject);
//               }}
//             >
//               <AddIcon /> Add
//               {x.name == "ReimbursementStatus_ProductStrength"
//                 ? "Product Storage"
//                 : x.label.replace("-", "")}
//             </button>
//           )}
//         </div>
//       </div>
//       {x.name !== "COA_Endpoints.EndpointType" && x.id !== 4897 ? (
//         <>
//           {parentObject &&
//             parentObject.map((v, vi) => {
//               return (
//                 <div key={vi} style={{ marginLeft: "10%" }}>
//                   {rows
//                     .filter((xc) => xc.masterFieldId == x.id)
//                     .map((xc, xci) => {
//                       if (!v[getHierarchyLeafName(x.name)])
//                         v[getHierarchyLeafName(x.name)] = [];
//                       let _parentObject = v[getHierarchyLeafName(x.name)];

//                       return (
//                         <>
//                           {xci == 0 && (
//                             <div key={vi}>
//                               <h5 className="float-left">
//                                 {x.label} {vi + 1}
//                               </h5>
//                               <div className="float-end">
//                                 {formMode != "read" && (
//                                   <button
//                                     className="btn btn-small"
//                                     type="button"
//                                     onClick={() => {
//                                       parentObject.splice(vi, 1);
//                                       fieldChanged();
//                                     }}
//                                   >
//                                     <RiDeleteBinLine /> Delete
//                                   </button>
//                                 )}
//                               </div>
//                             </div>
//                           )}
//                           {!masterFieldTypes.includes(xc.fieldTypeId) && (
//                             <FieldView
//                               field={xc}
//                               values={values}
//                               vi={vi}
//                               parentObject={v}
//                               fieldChanged={fieldChanged}
//                               RichtextFieldChanged={RichtextFieldChanged}
//                               datasourcename={datasourceName}
//                               datasourceDbName={datasourceDbName}
//                               section={el.name}
//                               datasourceId={dataSourceID}
//                               setLookupLabelList={setLookupLabelList}
//                               allFields={rows}
//                             ></FieldView>
//                           )}
//                           {masterFieldTypes.includes(xc.fieldTypeId) &&
//                             renderType1Detail(xc, el, _parentObject)}{" "}
//                         </>
//                       );
//                     })}
//                   <Divider />
//                 </div>
//               );
//             })}
//         </>
//       ) : (
//         openEndpointResult &&
//         endpointResultList &&
//         endpointResultList.length > 0 && (
//           <>
//             {endpointResultList.map((item: any, sectionKey: number) => (
//               <section key={sectionKey}>
//                 <h5 style={{ fontWeight: 700 }}>
//                   Endpoint Type - {item.Value}
//                 </h5>
//                 {endPointResultOption.length > 0 && (
//                   <div style={{ margin: "15px 30px" }} className="row">
//                     <div style={{ width: 500 }}>
//                       <Autocomplete
//                         multiple
//                         filterSelectedOptions
//                         renderTags={() => null}
//                         options={endPointResultOption}
//                         value={selectedEndpointResult}
//                         onChange={endpointResultSelection(item.Id)}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             placeholder="+ Results"
//                             variant="outlined"
//                           />
//                         )}
//                       />
//                     </div>
//                     <span
//                       style={{ cursor: "pointer", paddingTop: 15 }}
//                       onClick={() =>
//                         setSelectedEndpointResult(endPointResultOption)
//                       }
//                     >
//                       <AddIcon /> Add all results
//                     </span>
//                     <span
//                       style={{
//                         cursor: "pointer",
//                         paddingTop: 15,
//                         marginLeft: 10,
//                       }}
//                       onClick={() => deleteEndpoint(item)}
//                     >
//                       <RiDeleteBinLine/> Endpoint type
//                     </span>
//                   </div>
//                 )}
//                 <div className="row" style={{ margin: "5px 30px" }}>
//                   <label style={{ paddingTop: 15, marginRight: 10 }}>
//                     COA endpoint name
//                   </label>
//                   <div>
//                     <TextField
//                       id={item.Value}
//                       style={{ width: 550 }}
//                       placeholder="COA endpoint name"
//                     />
//                   </div>
//                 </div>
//                 {selectedEndpointResult.length > 0 && (
//                   <>
//                     {selectedEndpointResult.map((field, index) => (
//                       <div
//                         key={index}
//                         className="row"
//                         style={{ margin: "5px 30px" }}
//                       >
//                         <label style={{ paddingTop: 15, marginRight: 10 }}>
//                           Result - {field.label}
//                         </label>
//                         <div>
//                           <TextField
//                             id={field.id}
//                             style={{ width: 550 }}
//                             placeholder=""
//                           />
//                         </div>
//                         <span
//                           style={{
//                             paddingTop: 15,
//                             marginLeft: 10,
//                             cursor: "pointer",
//                           }}
//                           onClick={() => deleteResult(field)}
//                         >
//                           {" "}
//                           <RiDeleteBinLine />{" "}
//                         </span>
//                       </div>
//                     ))}
//                   </>
//                 )}
//               </section>
//             ))}
//           </>
//         )
//       )}
//     </>
//   );
// };



const base64ToArrayBuffer = async(base64:string,ClientNewsFileId:number,record_ID: number) => {
  let binaryString : any
  try{
    binaryString = atob(base64);
  }catch(error){
    const res:any = await apiCall(`/api/clientnews/files/${record_ID}/${ClientNewsFileId}`);
    binaryString = res.data;
    let bytes = new Uint8Array(res.data[0].FileData.data).buffer;
    return bytes
  }

  let bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

interface IClientNewsFiles {
  bufferData: any,
  type: string,
  fileName: string,
  NewlyAdded: boolean,
  ClientNewsFileId: number,
  recordId: number
}

const ClientNewsFiles = ({bufferData,type,fileName,NewlyAdded,ClientNewsFileId,recordId}:IClientNewsFiles) => {
  const [url, setUrl] = useState<any>();
  useEffect(() => {
    if (!NewlyAdded) {
      base64ToArrayBuffer(bufferData, ClientNewsFileId, recordId).then((_data: any) => {
        const blob = new Blob([_data], {
          type: type,
        });
        const _url = URL.createObjectURL(blob);
        setUrl(_url)
      });
    }
  }, [bufferData])
  if (NewlyAdded) {
    return <label className="col-sm-11">{fileName}</label>
  } else {

    return <a className="col-sm-11 iqvia-color" href={url} download={fileName} > {url ? fileName : "Loading"}</a>
  }
}
